import { Link, Typography } from '@mui/material';
import React from 'react';

const FooterLink = ({ text, link = '/', mail }) => {
  // Check if mail prop is provided
  const handleMailClick = () => {
    if (mail) {
      window.location.href = `mailto:${mail}`;
    }
  };

  return mail ? (
    <Typography
      variant='p'
      component='span'
      onClick={handleMailClick}  // Handle mail click
      sx={{
        fontSize: '0.9rem',
        fontWeight: '400',
        textDecoration: 'none',
        color: '#fff',
        textTransform: 'capitalize',
        cursor: 'pointer', // Make the text clickable
        "&:hover": {
          color: '#1c2859',
        }
      }}
    >
      {text}
    </Typography>
  ) : (
    <Link 
      href={link}
      variant='p'
      component='a'
      sx={{
        fontSize: '0.9rem',
        fontWeight: '400',
        textDecoration: 'none',
        color: '#fff',
        textTransform: 'capitalize',
        "&:hover": {
          color: '#1c2859',
        }
      }}
    >
      {text}
    </Link>
  );
};

export default FooterLink;
