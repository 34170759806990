import React, { useContext, useEffect, useState } from 'react';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import Header from '../../components/Header/Header';
import CircularImage from '../../components/CircularImage/CircularImage';
import { whatData } from '../../data/DummyValues';
import PromptList from '../../components/PromptList/PromptList';
import { backgrounds } from '../../data/backgrounds';
import { icons, iconsData } from '../../data/icons';
import axiosInstance from '../../services/axios';
import { UserContext } from '../../providers/UserProvider';
import Loader from '../../components/Loader/Loader';
import { useNavigate } from 'react-router-dom';

import { FaArrowLeft, FaBars, FaTimes } from 'react-icons/fa'; // Importing icons from react-icons
import Modal from '../../components/Modal/Modal';
import AboutModal from '../../components/AboutModal/AboutModal';
import ReportModal from '../../components/ReportModal/ReportModal';

const What = ({ post, setPageProgress, type }) => {
    const backgroundImageUrl = post?.image;
    const { user } = useContext(UserContext);
    const [prankData, setPrankData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState('');
    const navigate = useNavigate();

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const onItemClick = (whatValue) => {
        setPageProgress('exit', { ask: false, value: whatValue });
    }

    const handleModal = (modal) => {
        if (modal !== 'privacy' && modal !== 'tos') {
            setShowModal(true);
            setMenuOpen(false);
            setModal(modal);
        } else {
            navigate(`/${modal}`);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModal('');
    };

    const icon = iconsData['date'];
    const prankIcon = iconsData['prankEmoji'];
    const tickIcon = iconsData['tick'];
    const crossIcon = iconsData['cross'];



    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axiosInstance.get('configurations/prank', {
                    headers: {
                        Authorization: `Bearer ${user.token}`
                    }
                });
                setPrankData(response.data); // Assuming response.data contains the fetched data
                console.log(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching prank data:', error);
                setLoading(false);
                // Handle error state if needed
            }
        };

        if (type === 'prank') {
            fetchData();
        }
    }, [type, user.token]);



    return (
        <>
            <BackgroundImage imageUrl={backgrounds[type]} />

            <div
                onClick={() => setPageProgress('checkout')}
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: 15,
                    backgroundColor: 'rgba(255, 255, 255, 0.36)', // 36% opacity of white
                    borderRadius: 20,
                    width: 20,
                    height: 20,
                    padding: 10,
                    position: 'absolute',
                    top: 10,
                    left: 10,
                }}
            >
                <FaArrowLeft style={{ color: '#ffffff' }} /> {/* Left arrow with 36% opacity */}
            </div>


            <div
                onClick={toggleMenu}
                style={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                    padding: '10px',
                    cursor: 'pointer',
                }}
            >
                {menuOpen ? <FaTimes size={24} color="#FFF" /> : <FaBars size={24} color="#FFF" />}
            </div>

            {menuOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: 50,
                        right: 10,
                        backgroundColor: '#000',
                        borderRadius: '20px',
                        padding: '30px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        zIndex: 120,
                    }}
                >
                    <div style={{ width: '100%', borderBottom: '1px solid grey', paddingBottom: '10px', marginBottom: '5px', textAlign: 'center' }} onClick={() => handleModal('about')}>
                        <span style={{ color: '#FFF', fontWeight: 'bold', cursor: 'pointer', fontSize: '1.5em' }}>About</span>
                    </div>
                    <div style={{ width: '100%', borderBottom: '1px solid grey', paddingBottom: '10px', marginBottom: '5px', textAlign: 'center' }} onClick={() => handleModal('privacy')}>
                        <span style={{ color: '#FFF', fontWeight: 'bold', cursor: 'pointer', fontSize: '1.5em' }}>Privacy</span>
                    </div>

                    <div style={{ width: '100%', borderBottom: '1px solid grey', paddingBottom: '10px', marginBottom: '5px', textAlign: 'center' }} onClick={() => handleModal('tos')}>
                        <span style={{ color: '#FFF', fontWeight: 'bold', cursor: 'pointer', fontSize: '1.5em' }}>Terms</span>
                    </div>
                    <div style={{ width: '100%', textAlign: 'center' }} onClick={() => handleModal('report')}>
                        <span style={{ color: 'red', fontWeight: 'bold', cursor: 'pointer', fontSize: '1.5em' }}>Report</span>
                    </div>
                </div>
            )}
            <CircularImage backgroundImageUrl={backgroundImageUrl} icon={iconsData[type]} />

            {type === 'prank' ? (
                <>
                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <div style={{ fontSize: '2em', textAlign: 'center', margin: '40px 20px', color: '#fff', fontWeight: '900' }}>
                                😀 Prank them into 👇 thinking they got a real {prankData?.type} 🤤
                            </div>

                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100vw',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                marginTop: '50px'
                            }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    padding: '20px',
                                    width: '80vw',
                                    alignItems: 'center',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                                    marginBottom: '20px',
                                    backgroundColor: '#fff',
                                    borderRadius: '20px'
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={iconsData[prankData?.type]} alt="Date Icon" style={{ width: '40px', height: '40px', marginRight: '10px' }} />
                                        <span style={{ fontWeight: '800' }}>They think it's real</span>
                                    </div>
                                    <img src={crossIcon} alt="Cross Icon" style={{ width: '20px', height: '20px' }} />
                                </div>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    padding: '20px',
                                    width: '80vw',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                                    backgroundColor: '#fff',
                                    borderRadius: '20px'
                                }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <img src={prankIcon} alt="Prank Icon" style={{ width: '40px', height: '35px', marginRight: '10px' }} />
                                        <span style={{ fontWeight: '800' }}>But it's a Prank</span>
                                    </div>
                                    <img src={tickIcon} alt="Tick Icon" style={{ width: '20px', height: '20px' }} />
                                </div>

                                <img src={iconsData['sendPrank']} onClick={() => onItemClick(prankData)} style={{ width: '80%', marginTop: '50px' }} />
                            </div>


                        </>
                    )}
                </>
            ) : (
                <>
                    <Header page={'what'} />
                    <PromptList data={whatData} onItemClick={onItemClick} type={type} />
                </>
            )}

            <Modal show={showModal} handleClose={handleCloseModal}>
                {modal === 'about' ? (
                    <AboutModal />
                ) : null}
                {modal === 'report' ? (
                    <ReportModal />
                ) : null}
            </Modal>
        </>
    );
}

export default What;
