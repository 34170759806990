import React from 'react';
import { Box, Typography, Container, Divider } from '@mui/material';

const PrivacyPolicy = () => {
  return (
    <Container maxWidth="md" style={{ backgroundColor: 'white', padding: '2rem', borderRadius: '8px' }}>
      <Box mb={4} textAlign="center">
        <Typography variant="h4" gutterBottom style={{ fontWeight: 'bold', color: '#FFE500' }}>
          Privacy Policy for "Checkout My Friend"
        </Typography>
        <Typography variant="subtitle1">
          Effective Date: This Privacy Policy was last updated on: August 28th 2024.
        </Typography>
      </Box>

      <Typography variant="body1" gutterBottom>
        Welcome to the Privacy Policy of <strong>"Checkout My Friend"</strong> which is made available by <strong>Extasy Technologies</strong> (“our”, “us”, “we”), a registered Partnership Firm located in - Kushal Konwar Path, Professor Colony, Rangagora Road, Tinsukia, Assam 786125, India. We recognize that your privacy is critically important to us, and we are committed to protecting your personal information.
      </Typography>

      <Typography variant="body1" gutterBottom>
        This Privacy Policy outlines how we collect, use, share, and protect your personal information, and describes your rights and choices regarding your information. This Privacy Policy applies to all users of the "Checkout My Friend" mobile application and any associated services (collectively referred to as the "Services"). By using our Services, you agree to the collection, use, and sharing of your information as described in this Privacy Policy.
      </Typography>

      <Divider style={{ backgroundColor: '#FFE500', margin: '2rem 0' }} />

      {/* Section 1: Personal Information Collection */}
      <Typography variant="h5" style={{ fontWeight: 'bold', color: '#FFE500' }}>
        1. Personal Information Collection
      </Typography>

      <Typography variant="body1" gutterBottom>
        <strong>1.1 Types of Personal Information We Collect</strong>
        <ul>
          <li>
            <strong>Account Information:</strong> When you sign up via Google OAuth or Apple OAuth, we collect your name, email address, profile picture, and gender.
          </li>
          <li>
            <strong>Third-Party Content:</strong> Content featuring third parties (your friends) that you upload to the app. You must obtain explicit consent from your friends before sharing their photos.
          </li>
          <li>
            <strong>Reactions:</strong> We collect user reactions or likes (e.g., date, fun, hookup, or prank) and associated pre-written messages.
          </li>
          <li>
            <strong>Friend’s Tag:</strong> We collect one-word descriptions of your friend, selected by you from predefined options.
          </li>
          <li>
            <strong>Instagram Username:</strong> We collect Instagram Usernames from users interacting with shared content via links on social media.
          </li>
          <li>
            <strong>Feedback and Correspondence:</strong> We collect information you provide when contacting us for support, submitting feedback, or reporting issues.
          </li>
          <li>
            <strong>Automatic Data Collection:</strong> We collect device and online activity data such as pages viewed, time spent, device type, browser, and IP address.
          </li>
        </ul>
      </Typography>

      <Typography variant="body1" gutterBottom>
        <strong>1.2 How We Collect Personal Information</strong>
        We collect information directly from you when you:
        <ul>
          <li>Download and interact with the "Checkout My Friend" app or website.</li>
          <li>Sign up using Google OAuth or Apple OAuth.</li>
          <li>Upload content, send reactions, or provide feedback.</li>
        </ul>
        We may also collect information from third-party sources such as marketing partners, publicly available sources, and data providers.
      </Typography>

      <Divider style={{ backgroundColor: '#FFE500', margin: '2rem 0' }} />

      {/* Section 2: Use of Personal Information */}
      <Typography variant="h5" style={{ fontWeight: 'bold', color: '#FFE500' }}>
        2. Use of Personal Information
      </Typography>

      <Typography variant="body1" gutterBottom>
        <strong>2.1 Purpose of Collecting Personal Information</strong>
        We use the personal information we collect for the following purposes:
        <ul>
          <li><strong>Providing and Supporting the Services:</strong> To operate and improve our Services, including managing your account and providing customer support.</li>
          <li><strong>Improvement and Personalization:</strong> To enhance and personalize your experience.</li>
          <li><strong>Marketing and Advertising:</strong> For direct marketing with your consent and interest-based advertising.</li>
          <li><strong>Compliance and Legal Obligations:</strong> To comply with legal requirements, enforce terms, and defend against claims.</li>
        </ul>
      </Typography>

      <Divider style={{ backgroundColor: '#FFE500', margin: '2rem 0' }} />

      {/* Section 3: Sharing of Personal Information */}
      <Typography variant="h5" style={{ fontWeight: 'bold', color: '#FFE500' }}>
        3. Sharing of Personal Information
      </Typography>

      <Typography variant="body1" gutterBottom>
        <strong>3.1 With Whom We Share Personal Information</strong>
        We may share your personal information with:
        <ul>
          <li>Other users in specific cases, such as revealing the identity of premium users who react to photographic content.</li>
          <li>Service providers assisting us in operating the Services, such as customer support, analytics, and fraud detection.</li>
          <li>Legal authorities in cases of fraud prevention, safety, or business transfers.</li>
        </ul>
      </Typography>

      <Divider style={{ backgroundColor: '#FFE500', margin: '2rem 0' }} />

      {/* Other sections like User Safety, Data Retention, Security, and Rights */}
      <Typography variant="h5" style={{ fontWeight: 'bold', color: '#FFE500' }}>
        4. User Safety and Content Moderation
      </Typography>

      <Typography variant="body1" gutterBottom>
        <strong>4.1 Reporting Inappropriate Content:</strong> If you encounter any content that violates our guidelines, report it directly through the app.
      </Typography>

      <Typography variant="body1" gutterBottom>
        <strong>4.2 Content Moderation Practices:</strong> Users must agree to our Photo Guidelines before uploading content. Violating the guidelines may lead to content removal or account suspension.
      </Typography>

      <Divider style={{ backgroundColor: '#FFE500', margin: '2rem 0' }} />

      <Typography variant="h5" style={{ fontWeight: 'bold', color: '#FFE500' }}>
        5. Data Retention
      </Typography>

      <Typography variant="body1" gutterBottom>
        We retain personal information for as long as necessary to fulfill the purposes outlined in this Privacy Policy, unless a longer retention period is required or permitted by law.
      </Typography>

      <Divider style={{ backgroundColor: '#FFE500', margin: '2rem 0' }} />

      <Typography variant="h5" style={{ fontWeight: 'bold', color: '#FFE500' }}>
        6. Data Security
      </Typography>

      <Typography variant="body1" gutterBottom>
        We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.
      </Typography>

      <Divider style={{ backgroundColor: '#FFE500', margin: '2rem 0' }} />

      <Typography variant="h5" style={{ fontWeight: 'bold', color: '#FFE500' }}>
        7. International Data Transfers
      </Typography>

      <Typography variant="body1" gutterBottom>
        Our services are available only within India, and all data processing is restricted to India.
      </Typography>

      <Divider style={{ backgroundColor: '#FFE500', margin: '2rem 0' }} />

      <Typography variant="h5" style={{ fontWeight: 'bold', color: '#FFE500' }}>
        8. Your Rights and Choices
      </Typography>

      <Typography variant="body1" gutterBottom>
        <strong>8.1 Accessing and Updating Your Information:</strong> You can manage your account information through the app, except for certain non-editable fields. Contact us for changes.
      </Typography>

      <Typography variant="body1" gutterBottom>
        <strong>8.2 Opting Out of Marketing Communications:</strong> You may opt-out of receiving marketing emails anytime.
      </Typography>

      <Typography variant="body1" gutterBottom>
        <strong>8.3 Withdrawing Consent:</strong> You can withdraw consent for processing your personal data at any time.
      </Typography>

      <Divider style={{ backgroundColor: '#FFE500', margin: '2rem 0' }} />

      <Typography variant="h5" style={{ fontWeight: 'bold', color: '#FFE500' }}>
        9. Children’s Privacy
      </Typography>

      <Typography variant="body1" gutterBottom>
        "Checkout My Friend" is not intended for users under the age of 18. We do not knowingly collect information from children.
      </Typography>

      <Divider style={{ backgroundColor: '#FFE500', margin: '2rem 0' }} />

      <Typography variant="h5" style={{ fontWeight: 'bold', color: '#FFE500' }}>
        10. Changes to This Privacy Policy
      </Typography>

      <Typography variant="body1" gutterBottom>
        We may update this Privacy Policy from time to time. Changes will be posted in the app or our website.
      </Typography>

      <Divider style={{ backgroundColor: '#FFE500', margin: '2rem 0' }} />

      <Typography variant="h5" style={{ fontWeight: 'bold', color: '#FFE500' }}>
        11. Contact Us
      </Typography>

      <Typography variant="body1" gutterBottom>
        If you have any questions about this Privacy Policy, please contact us at: 
        <br />Kushal Konwar Path, Professor Colony, Rangagora Road, Tinsukia, Assam 786125, India.
      </Typography>
    </Container>
  );
};

export default PrivacyPolicy;
