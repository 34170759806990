import React from 'react';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';

const Learn = ({ setPageProgress }) => {

    const handleOnClick = () => {
        setPageProgress('consent', 'consent');
    };

    return (
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' , height: '100vh'}}>
            <BackgroundImage imageUrl={''} />

            <div style={{ padding: '20px', color: 'white', textAlign: 'left' , display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly' , flex: 1}}>
                <h3 style={{ fontWeight: 'bold', fontSize: '1.5em', color: 'yellow' }}>
                    {/* Title */}
                    What's Happening<span style={{ color: 'white' }}>?</span>
                </h3>
                <p style={{ fontWeight: 'bold', color: 'white' }}>
                    {/* Description */}
                    This is <span style={{ fontWeight: 'bolder', fontSize: '1.2em' }}>'Checkout My Friend'</span> where one shares their best friend’s photo in their story. The Friend then gets crazy votes like <span style={{ fontWeight: 'bolder', fontSize: '1.2em' }}>‘Date’</span>, <span style={{ fontWeight: 'bolder', fontSize: '1.2em' }}>‘Marry’</span>, or <span style={{ fontWeight: 'bolder', fontSize: '1.2em' }}>‘Hangout’</span> from others.
                </p>

                <h3 style={{ fontWeight: 'bold', fontSize: '1.5em', color: 'yellow' }}>
                    {/* Title */}
                    What you get<span style={{ color: 'white' }}>?</span>
                </h3>
                <p style={{ fontWeight: 'bold', color: 'white' }}>
                    {/* Description */}
                    Laughs and maybe bragging rights, because in the end, you’ll see which tag you got the most. Are you the <span style={{ fontWeight: 'bolder', fontSize: '1.2em' }}>date</span> everyone’s looking for, the <span style={{ fontWeight: 'bolder', fontSize: '1.2em' }}>ideal marriage material</span> or the <span style={{ fontWeight: 'bolder', fontSize: '1.2em' }}>ultimate fun buddy</span>?
                </p>

                <h3 style={{ fontWeight: 'bold', fontSize: '1.5em', color: 'yellow' }}>
                    {/* Title */}
                    Only for Fun<span style={{ color: 'white' }}>:</span>
                </h3>
                <p style={{ fontWeight: 'bold', color: 'white' }}>
                    {/* Description */}
                    This is just for fun. No Judging, No Objectifying, only Love, Cheers, Positivity & just good memories!
                </p>
            </div>

            <div
                style={{
                    backgroundColor: 'white',
                    color: 'black',
                    width: '80%',
                    margin: '20px auto',
                    borderRadius: '50px',
                    padding: '20px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    textAlign: 'center',
                    cursor: 'pointer'
                }}
                onClick={handleOnClick}
            >
                Close
            </div>
        </div>
    );
};

export default Learn;
