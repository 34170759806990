import React from 'react'
import { iconsData } from '../../data/icons'
import CircularImage from '../CircularImage/CircularImage'

const InstagramModal = ({photo, type}) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

            <CircularImage backgroundImageUrl={photo} icon={iconsData[type]} />

            <p style={{ color: '#fff', textAlign: 'center', fontSize: '1.5em', lineHeight: '1.5', fontWeight: '700', marginTop: 10 }}>Add Instagram To Connect</p>
        
        </div>
    )
}

export default InstagramModal