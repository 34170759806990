import React from 'react';
import './SafetyCenter.css';

const SafetyCenter = () => {
  return (
    <div className="safety-center-page">
      <section className="safety-section">
        <h1 className="safety-title">Safety Center</h1>
        <p className="safety-intro">
          At "Checkout My Friend", the safety and security of our users is not just a priority; it is the very foundation upon which our app is built. We understand that a fun, engaging, and lighthearted experience can only thrive in an environment where users feel secure and respected. To this end, we have invested significant resources into developing a comprehensive safety framework that is both proactive and responsive. This framework is continuously reviewed and enhanced to adapt to emerging challenges, ensuring that our community remains a safe haven for positive interactions.
        </p>
        <p className="safety-intro">
          Our secure ecosystem is designed to operate with precision, constantly monitored by a dedicated team to identify and respond to potential risks. We employ a lot of standard practices to avoid any guideline violations, but we also rely on the vigilance of our community members to report anything that seems inappropriate or harmful. When you report an issue, you are not just safeguarding your own experience; you are contributing to the well-being of the entire community.
        </p>
        <p className="safety-intro">
          If you ever encounter something that feels off or threatens your sense of security, we encourage you to take immediate action by reaching out to us at <a href="mailto:help@checkoutmyfriend.link" className="safety-email">help@checkoutmyfriend.link</a>. We treat every report with the seriousness it deserves, and your concerns will be addressed promptly and with full attention.
        </p>
      </section>

      <section className="ethical-commitment">
        <h2 className="safety-subtitle">Our Ethical Commitment</h2>
        <p className="safety-text">
          "Checkout My Friend" is deeply committed to fostering an environment where respect, kindness, and positivity are the norm. We have established a zero-tolerance policy for behaviors that undermine these values, such as bullying, hate speech, trolling, and human objectification.
        </p>
        <p className="safety-text">
          To prevent such negative interactions, we have carefully designed our app in a way that minimizes the potential for misuse. Unlike many other apps, "Checkout My Friend" does not allow for open-ended communications, which can often be a breeding ground for harmful or offensive material. Instead, we provide a curated set of pre-defined prompts that users can choose from, ensuring that interactions remain safe, fun, and within the bounds of our community guidelines.
        </p>
        <p className="safety-text">
          These prompts—ranging from playful pickup lines to heartfelt compliments—are crafted to encourage safe and positive engagement. They allow users to express themselves in a way that is both creative and secure, without the risk of crossing boundaries or causing harm. By removing the potential for harmful content to be created or shared, we have significantly reduced the likelihood of negative experiences on our app. This approach strikes a delicate balance: it provides users with the freedom to connect and interact, while also maintaining a secure environment that prioritizes safety and respect.
        </p>
      </section>

      <section className="reporting-content">
        <h2 className="safety-subtitle">Reporting Inappropriate Content</h2>
        <p className="safety-text">
          "Checkout My Friend" provides users with an easy and effective way to report any photographic content they find inappropriate, offensive, or harmful. Your vigilance is a crucial part of our safety strategy, and we have made the reporting process as simple and effective as possible.
        </p>
        <p className="safety-text">
          If you come across any photographic content that violates our community guidelines, you can report it directly through our app. To do so, simply navigate to the menu option, located at the top-right of the image. From there, click on the "Report" option. You will be asked to provide specific reasons for your report, which helps our team understand the nature of the issue and respond accordingly.
        </p>
        <p className="safety-text">
          Once your report is submitted, our team will conduct a thorough review of the content. We take each report seriously and approach them with a rigorous process of evaluation, which may take anywhere from a few hours to a few days, depending on the volume of reports we receive at that time. If the photo receives multiple reports within a short timeframe, it will be flagged as a priority and temporarily removed from the app while further investigation takes place. This precautionary measure ensures that potentially harmful content is not visible while we assess the situation.
        </p>
        <p className="safety-text">
          Our review process is meticulous. We consider the context, intent, and potential impact of the reported content before making a decision. If the content is found to be in violation of our guidelines, it will be taken down, and the user responsible may face consequences, including a permanent ban from the app depending on the intensity of the violation. In cases where the content is found to be particularly egregious or unlawful, we will not hesitate to escalate the matter to law enforcement authorities for appropriate legal action.
        </p>
        <p className="safety-text">
          By reporting inappropriate photographic content, you are playing an active role in maintaining the safety and integrity of our community. Your actions help us create a space where everyone can enjoy themselves without fear of encountering harmful or offensive material. We encourage you to use this feature whenever you feel it is necessary—your participation is vital to the health of our app.
        </p>
      </section>

      <section className="consent">
        <h2 className="safety-subtitle">The Importance of Consent</h2>
        <p className="safety-text">
          At the heart of "Checkout My Friend" lies a commitment to fostering genuine connections and friendships. Our app is designed to bring people closer together in a fun, lighthearted way, but this can only happen when trust and respect are at the forefront of every interaction. One of the most important aspects of this trust is the concept of consent, particularly when it comes to sharing photos of friends.
        </p>
        <p className="safety-text">
          Consent is not just a formality—it is a fundamental principle that ensures all interactions on our app are grounded in mutual respect and understanding. Before a photo of a friend can be shared on "Checkout My Friend", explicit consent must be obtained from the person in the photo. This is not an optional step; it is a mandatory requirement that upholds the integrity of our community.
        </p>
        <p className="safety-text">
          When someone asks for your photo to share on "Checkout My Friend", it is important that you take the time to consider the request carefully. Ask yourself whether you are comfortable with your photo being shared in this context. Reflect on the relationship you have with the person making the request—do you trust them? Do you believe they have good intentions? It is essential that you feel fully confident in your decision before giving your consent.
        </p>
        <p className="safety-text">
          In addition to considering your comfort level, it is also important to specify which photo you would like to be used. This allows you to maintain control over your photographic representation and ensures that you are represented in a way that you are comfortable with. When discussing the request with your friend, make sure they clearly explain how your image will be used, what the app is about, and the purpose behind the request. This conversation should leave you with a full understanding of what this is all about and how it will impact you.
        </p>
        <p className="safety-text">
          Even after you have given your consent, "Checkout My Friend" provides you with ongoing control over your content. If at any point you begin to feel uncomfortable with the situation, you can opt out and delete your content from the app. This flexibility is available to you at all times, whether you are inside the app or responding to an invitation link. Your consent is not a one-time decision—it is a continuous process, and you have the right to withdraw it whenever you choose.
        </p>
        <p className="safety-text">
          Unfortunately, there may be situations where your photo is used without your consent. If this happens, it is important to take immediate action to protect your rights. You can report the unauthorized use of your photo by contacting us at <a href="mailto:help@checkoutmyfriend.link" className="safety-email">help@checkoutmyfriend.link</a>. Required information for content takedown request includes the following:
        </p>
        <ul className="safety-list">
          <li><strong>Identification Details:</strong> Full Name of the complainant, Contact Information (phone number), Two government-issued IDs for identity verification (e.g., Passport, Aadhaar, Driver's License, PAN).</li>
          <li><strong>Detailed Description of the Unauthorized Content:</strong> URL(s) or specific location(s) of the content on the app, Date and time the content was uploaded (if known).</li>
          <li><strong>Proof of Ownership or Legal Rights:</strong> For personal images: A statement affirming that the complainant is the individual depicted in the content or has the legal right to control its distribution.</li>
        </ul>
        <p className="safety-text">
          Our team will review your request and take appropriate action to remove the content if it is found to be in violation of our policies. We are committed to ensuring that your rights and privacy are upheld at all times.
        </p>
        <p className="safety-text">
          By respecting these guidelines and actively participating in the consent process, you contribute to a safer and more respectful community on "Checkout My Friend". Your proactive approach to consent not only helps protect yourself but also supports the creation of a positive environment where everyone can feel valued and secure.
        </p>
      </section>

      <footer className="footer">
        <p>For any additional questions or concerns, please contact us at <a href="mailto:help@checkoutmyfriend.link" className="safety-email">help@checkoutmyfriend.link</a>.</p>
      </footer>
    </div>
  );
};

export default SafetyCenter;
