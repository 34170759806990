import * as firebase from "firebase/app";

import "firebase/auth";
import "firebase/firestore";
import axiosInstance from "./axios";



firebase.initializeApp({
    apiKey: "AIzaSyBoJCsmIRVvvOhCDzSJaXErxExWZ-Qm_Tk",
    authDomain: "spotme-app-dfa54.firebaseapp.com",
    databaseURL: "https://spotme-app-dfa54-default-rtdb.asia-southeast1.firebasedatabase.app",
    projectId: "spotme-app-dfa54",
    storageBucket: "spotme-app-dfa54.appspot.com",
    messagingSenderId: "671673748572",
    appId: "1:671673748572:web:1a7bef3d6fac525be4aa99",
    measurementId: "G-XFB0L3Z9BC"
});



export const auth = firebase.auth();
const googleProvider = new firebase.auth.GoogleAuthProvider()
export const signInWithGoogle = () => {
    
    auth.signInWithPopup(googleProvider).then(async (res) => {
        const idToken = await res.user.getIdToken();
        // Make the POST request to your backend
        await axiosInstance.post('/users/signup', {}, {
            headers: {
                'Authorization': `Bearer ${idToken}`
            }
        });
        return res;
    }).catch((error) => {
        console.log(error.message)
    })
}
export const logOut = () => {
    auth.signOut().then(() => {
        console.log('logged out')
    }).catch((error) => {
        console.log(error.message)
    })
}