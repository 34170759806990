import React from 'react';
import {
    AppBar,
    Toolbar,
    Box,
    styled
} from '@mui/material';
// assets
import cmfLogo from '../assets/cmf-landing-logo.png';
import backgroundImg from '../assets/landing-bac.png'

// custom styling
const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'center',
     alignItems: 'center',
     padding: 10
});

const Navbar = () => {
    return (
        <AppBar 
            component="nav" 
            position="sticky"
            sx={{ 
                background: `black`, 
            }}
            elevation={0}
        >
            <StyledToolbar>
                <Box
                    component="img"
                    src={cmfLogo}
                    alt="CheckoutMyFriend Logo"
                    sx={{ 
                       
                        width: '20vw', // adjust logo size as needed
                        height: '5vh',
                    }}
                />
            </StyledToolbar>
        </AppBar>
    );
}

export default Navbar;
