export const whyData = [
    {
        _id: "1",
        title: "She seems to be a sweet one"
    },
    {
        _id: "2",
        title: "She seems to be a sweet one"
    },

    {
        _id: "3",
        title: "She seems to be a sweet one"
    },

    {
        _id: "4",
        title: "She seems to be a sweet one"
    },

    {
        _id: "5",
        title: "She seems to be a sweet one"
    },

    {
        _id: "6",
        title: "She seems to be a sweet one"
    },
    {
        _id: "7",
        title: "She seems to be a sweet one"
    },

    {
        _id: "8",
        title: "She seems to be a sweet one"
    },

    {
        _id: "9",
        title: "She seems to be a sweet one"
    },

    {
        _id: "10",
        title: "She seems to be a sweet one"
    },

    {
        _id: "11",
        title: "She seems to be a sweet one"
    },
    {
        _id: "12",
        title: "She seems to be a sweet one"
    },

    {
        _id: "13",
        title: "She seems to be a sweet one"
    },

    {
        _id: "14",
        title: "She seems to be a sweet one"
    },

    {
        _id: "15",
        title: "She seems to be a sweet one"
    }
];


export const whatData = [
    {
        _id: "1",
        title: "She seems to be a sweet one"
    },
    {
        _id: "2",
        title: "She seems to be a sweet one"
    },

    {
        _id: "3",
        title: "She seems to be a sweet one"
    },

    {
        _id: "4",
        title: "She seems to be a sweet one"
    },

    {
        _id: "5",
        title: "She seems to be a sweet one"
    },

    {
        _id: "6",
        title: "She seems to be a sweet one"
    },
    {
        _id: "7",
        title: "She seems to be a sweet one"
    },

    {
        _id: "8",
        title: "She seems to be a sweet one"
    },

    {
        _id: "9",
        title: "She seems to be a sweet one"
    },

    {
        _id: "10",
        title: "She seems to be a sweet one"
    },

    {
        _id: "11",
        title: "She seems to be a sweet one"
    },
    {
        _id: "12",
        title: "She seems to be a sweet one"
    },

    {
        _id: "13",
        title: "She seems to be a sweet one"
    },

    {
        _id: "14",
        title: "She seems to be a sweet one"
    },

    {
        _id: "15",
        title: "She seems to be a sweet one"
    }
]

export const pageTitles = {
    logo: require('./../assets/page-titles/cmf-o-logo.png'),
    why: require('./../assets/page-titles/cmf-why.png'),
    what: require('./../assets/page-titles/what.png'),
    ask: require('./../assets/page-titles/cmf-ask.png')
}