import { CircularProgress } from '@material-ui/core'
import React from 'react'
import { iconsData } from '../../data/icons'

function Loader() {
  return (
    <div style={{height: '100vh', width: '100vw', backgroundColor: 'black', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', flex: 1}}>
    
    <img src={iconsData['cmfWhite']} style={{width: '50%', marginBottom: '60px'}}/>

    <CircularProgress />
 </div>
  )
}

export default Loader