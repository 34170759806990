import React, { useState } from 'react';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import CircularImage from '../../components/CircularImage/CircularImage';
import { backgrounds } from '../../data/backgrounds';
import { iconsData } from '../../data/icons';
import ConsentModal from '../../components/ConsentModal/ConsentModal';
import Modal from '../../components/Modal/Modal';


const Consent = ({ post, setPageProgress }) => {

    const [showModal, setShowModal] = useState(false);

    const backgroundImageUrl = post?.image;

    const onConsentClick = (checkoutValue) => {
        if (checkoutValue === 'learn') {
            setPageProgress('learn', checkoutValue);
        } else {
            setPageProgress('exitInvite', checkoutValue);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
    }

    const removePost = () => {
        onConsentClick('no');
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', }}>
            <BackgroundImage imageUrl={backgrounds['prank']} />

            <CircularImage backgroundImageUrl={backgroundImageUrl} />


            <div style={{
                        alignSelf: 'center',
                        padding: '10px',
                        cursor: 'pointer',
                        backgroundColor: '#FFF',
                        color: '#000',
                        borderRadius: '20px',
                         fontWeight: '700',
                         display: 'flex',
                         alignItems: 'center',
                         fontSize: '0.8em',
                         marginTop: '20px'
                    }}
                   
                    >
                        Just for Fun 😉
                    </div>

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '30px', marginTop: '20px' }}>
                <img src={iconsData['consentMessage']} style={{ width: '80%', marginBottom: '20px' }} />

                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}   onClick={() => onConsentClick('learn')}>
                    <p style={{
                        color: 'white', fontWeight: '700', fontSize: '1.2em', display: 'inline-block',
                        borderBottom: '2px solid white',
                        marginRight: '10px',
                        paddingBottom: '5px', // Adjust the value as needed for spacing
                        marginBottom: '10px'
                    }}> Learn more </p>

                    <p style={{ marginBottom: '10px' }}>➡️</p>
                </div>
            </div>


            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', alignItems: 'center', }}>

                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} onClick={() => onConsentClick('yes')}>
                    <img src={iconsData['joinButton']} style={{width: '80%'}} />
                </div>
                
                <div
                    style={{
                        backgroundColor: '#242424',
                        color: 'white',
                        width: '70%',
                        padding: '25px',
                        margin: '10px 0',
                        borderRadius: '50px',
                        textAlign: 'center',
                        fontWeight: 'bold',

                    }}
                    onClick={() => setShowModal(true)}
                >
                    Remove this post
                </div>
               
            </div>

            <Modal show={showModal} handleClose={handleCloseModal} showCloseBtn={false}>
               <ConsentModal removePost={removePost} handleCloseModal={handleCloseModal} />
            </Modal>
        </div>
    );
}

export default Consent;
