import React from 'react';
import './CommunityGuidelines.css';

const CommunityGuidelines = () => {
  return (
    <section className="guidelines-section">
      <div className="guidelines-container">
        <h2 className="guidelines-title">Community Guidelines of “Checkout My Friend”</h2>
        <p className="guidelines-intro">
          Welcome to “Checkout My Friend”, a fun and engaging wingman tool designed for celebrating friendship, sparking curiosity, and discovering who secretly likes your friend, all while keeping things fun and light-hearted. Here, friendship meets matchmaking in a space built on trust, consent, and mutual respect. To ensure a safe and enjoyable environment for everyone, we've established these Community Guidelines. Please review them carefully, along with our Terms of Service and Privacy Policy, to understand the expectations and responsibilities within our community.
        </p>

        <h3 className="guidelines-subtitle">How Should I Use “Checkout My Friend”?</h3>
        <div className="guidelines-content">
          <h4 className="guidelines-subheading">Be a Good Wingman</h4>
          <p className="guidelines-text">
            You must have explicit consent from your friend before uploading their photo and clearly explain how their photo will be used, including the potential outcomes. When you involve your friend the right way, it creates a better experience for everyone. Plus, it's a great way to show your friend you care about their privacy and comfort. Mutual agreement is mandatory before sharing any content. Consent isn't optional—it’s essential.
          </p>
          <h4 className="guidelines-subheading">Be Authentic</h4>
          <p className="guidelines-text">
            Ensure that the photo and any other information you provide comply with our guidelines. This includes using your real name, profile picture, Instagram Username, and gender during account creation. Authenticity enhances the experience for everyone and builds trust within the community.
          </p>
          <h4 className="guidelines-subheading">Share Responsibly</h4>
          <p className="guidelines-text">
            If you're inviting your friend to participate by sharing an invitation link, make sure to share it only with the intended friend. Misuse of the invitation link or sharing it with unintended parties can lead to consequences, including account suspension.
          </p>
          <h4 className="guidelines-subheading">Respect Your Friend’s Rights</h4>
          <p className="guidelines-text">
            As a friend, you have the right to be fully informed about how your photo will be used. If, at any point, you feel uncomfortable or wish to withdraw your consent, you can remove the photo. It's your choice, and your comfort is our priority.
          </p>
          <h4 className="guidelines-subheading">Have Fun!</h4>
          <p className="guidelines-text">
            Above all, “Checkout My Friend” is designed to be a fun and light-hearted space. Users are encouraged to enjoy themselves, explore new connections, and create lasting memories. However, this enjoyment should never come at the expense of another person’s comfort or dignity. Always act with kindness, respect, and consideration.
          </p>
        </div>

        <h3 className="guidelines-subtitle">What’s Not Allowed on Checkout My Friend? ❌</h3>
        <ul className="guidelines-list">
          <li className="guidelines-item">
            <h4 className="guidelines-list-heading">Sharing Content Without Consent</h4>
            <p className="guidelines-list-text">Uploading a friend's photo without their explicit consent and without clearly explaining the purpose and potential outcomes is strictly forbidden.</p>
          </li>
          <li className="guidelines-item">
            <h4 className="guidelines-list-heading">Violence, Abuse, and Harassment</h4>
            <p className="guidelines-list-text">No content that promotes or encourages violence, harassment, or bullying is allowed. This includes any form of child abuse, sexual harassment, or abusive language or symbol.</p>
          </li>
          <li className="guidelines-item">
            <h4 className="guidelines-list-heading">Harmful or Illegal Content</h4>
            <p className="guidelines-list-text">No sharing or promotion of illegal activities, including but not limited to, drug use, weapons, illegal substances, or any form of criminal behavior. Any form of spamming, trolling, or spreading misinformation is prohibited.</p>
          </li>
          <li className="guidelines-item">
            <h4 className="guidelines-list-heading">Personal Information and Confidentiality</h4>
            <p className="guidelines-list-text">Do not expose personal, confidential, or sensitive information about yourself or others. Misusing or sharing someone else's personal information without their consent is a serious violation.</p>
          </li>
          <li className="guidelines-item">
            <h4 className="guidelines-list-heading">No Sexual Content or Nudity</h4>
            <p className="guidelines-list-text">The sharing of explicit sexual content, including but not limited to, nudity or sexually suggestive material, is strictly forbidden.</p>
          </li>
          <li className="guidelines-item">
            <h4 className="guidelines-list-heading">No Spam or Unauthorized Promotion</h4>
            <p className="guidelines-list-text">Posting unsolicited promotional material, junk mail, or other forms of solicitation is not allowed. This includes promoting brands, services, or products not affiliated with “Checkout My Friend”.</p>
          </li>
        </ul>

        <h3 className="guidelines-subtitle">What Should I Do If I See a Violation? ⚠️</h3>
        <ol className="guidelines-steps">
          <li className="guidelines-step">
            <h4 className="guidelines-step-heading">Report the Content</h4>
            <p className="guidelines-step-text">If you see an inappropriate or offensive photo, you can report it directly through the web. Simply click the menu option on the top right of the image and select "Report." Choose the appropriate reason for your report, and our team will review it promptly.</p>
          </li>
          <li className="guidelines-step">
            <h4 className="guidelines-step-heading">Remove Your Content</h4>
            <p className="guidelines-step-text">As a friend, if you feel uncomfortable with your photo being shared, you can delete it at any time. Your consent and comfort are paramount, and we support your right to withdraw at any moment.</p>
          </li>
          <li className="guidelines-step">
            <h4 className="guidelines-step-heading">Contact Us</h4>
            <p className="guidelines-step-text">If someone is using your photo without your consent, or if you encounter a severe violation, contact us at help@checkoutmyfriend.link. We will take appropriate actions, which may include banning the user and cooperating with law enforcement if necessary.</p>
          </li>
        </ol>

        <h3 className="guidelines-subtitle">Consequences of Violations</h3>
        <p className="guidelines-text">
          Violating these guidelines can lead to serious consequences, including:
        </p>
        <ul className="guidelines-consequences">
          <li className="guidelines-consequence-item"><strong>Content Removal:</strong> Any content found to be in violation of these guidelines will be removed promptly.</li>
          <li className="guidelines-consequence-item"><strong>Account Suspension or Ban:</strong> Depending on the severity of the violation, users may face account suspension or permanent termination. Repeat offenders or those involved in serious breaches will be banned from the platform.</li>
          <li className="guidelines-consequence-item"><strong>Legal Action:</strong> In cases involving criminal activity or severe breaches of our guidelines, “Checkout My Friend” reserves the right to cooperate with law enforcement and pursue legal action against the offending party.</li>
        </ul>

        <h3 className="guidelines-subtitle">Compliance with Local Laws</h3>
        <p className="guidelines-text">
          By using “Checkout My Friend”, you agree to comply with all applicable laws and regulations governing content sharing. Particularly, if you are in India, you must ensure that your use of the app adheres to Indian law. Misuse of the app in a manner that violates local laws is prohibited and will be dealt with accordingly.
        </p>

        <h3 className="guidelines-subtitle">Regular Revisions</h3>
        <p className="guidelines-text">
          Our Community Guidelines may be updated periodically to reflect new policies, legal requirements, or changes to our platform. All updates will take effect immediately upon publication.
          Users will be notified of significant changes to these guidelines. However, it is the responsibility of each user to stay informed about the current guidelines and to review them regularly.
        </p>
        <p className="guidelines-text">
          By participating in the “Checkout My Friend” community, you agree to adhere to these guidelines and to contribute to a safe, and respectful environment for all users. Failure to comply with these guidelines may result in the removal of post, suspension or termination of your account, and in some cases, legal action. We appreciate your cooperation in maintaining the integrity and fun spirit of “Checkout My Friend”.
        </p>
      </div>
    </section>
  );
};

export default CommunityGuidelines;
