import React, { useContext, useState } from 'react';
import { UserContext } from '../../providers/UserProvider';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import CircularProgress from '@material-ui/core/CircularProgress';
import axiosInstance from '../../services/axios';

const ReportModal = () => {
    const { user } = useContext(UserContext);
    const { id } = useParams();
    const [status, setStatus] = useState('initial');
    const [responseMessage, setResponseMessage] = useState('');
    
    const reasons = [
        'Impersonation', 'Nudity', 'Violence', 'Bullying', 'Child Abuse'
    ];

    const handleReportClick = async (reason) => {
        setStatus('loading');
        try {
            const response = await axiosInstance.post(
                '/reports',
                { postId: id, reason: reason },
                { headers: { Authorization: `Bearer ${user.token}` } }
            );
            setResponseMessage(response.data.message);
            setStatus('response');
        } catch (error) {
            setResponseMessage('Error reporting the post.');
            setStatus('response');
        }
    };

    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' , width: '100%'}}>
            <h2 style={{ color: '#FFF', fontWeight: 'bolder', fontSize: '1.5em', marginBottom: '10px'}}>Why Report?</h2>
            {status === 'initial' && reasons.map((reason, index) => (
                <div
                    key={index}
                    onClick={() => handleReportClick(reason)}
                    style={{
                        width: '100%',
                        backgroundColor: '#242424',
                        padding: '15px',
                        textAlign: 'center',
                        margin: '5px 0',
                        cursor: 'pointer',
                        borderRadius: '5px',
                        color: '#FFF',
                        fontWeight: 'bold'
                    }}
                >
                    {reason}
                </div>
            ))}
            {status === 'loading' && <CircularProgress style={{ color: '#FFF', marginTop: '20px' }} />}
            {status === 'response' && (
                <p style={{ color: '#FFF', fontWeight: 'bold', fontSize: '1.5em', textAlign: 'center' }}>
                    {responseMessage}
                </p>
            )}
        </div>
    );
};

export default ReportModal;
