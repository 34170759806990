import React, { useContext, useEffect, useState } from 'react';
import { UserContext } from '../../providers/UserProvider';
import CheckoutDummy from '../CheckoutDummy/CheckoutDummy';
import UAParser from 'ua-parser-js';
import { logOut } from '../../services/Firebase';
import axiosInstance from '../../services/axios';
import { useNavigate, useParams } from 'react-router-dom';
import './CheckoutInvite.css';
import Invite from '../Invite/Invite';
import Consent from '../Consent/Consent';
import Learn from '../Learn/Learn';
import ExitInvite from '../ExitInvite/ExitInvite';
import CheckoutDummyInvite from '../CheckoutDummyInvite/CheckoutDummyInvite';
import { CircularProgress } from '@material-ui/core';
import Loader from '../../components/Loader/Loader';


function CheckoutInvite() {
    const {user} = useContext(UserContext);
    const { id } = useParams();

    const [isNative, setIsNative] = useState(null);
    const [post, setPost] = useState(null);

  
    const [blurredImage, setBlurredImage] = useState(''); 

    const [consentValue, setConsentValue] = useState('');
    const [exitValue, setExitValue] = useState('');
    const [progress, setProgress] = useState('checkout-invite');
    const [subject, setSubject] = useState(null);
    const navigate = useNavigate();


    useEffect(() => {
        const parser = new UAParser();
        const result = parser.getResult();
        const userAgent = navigator.userAgent;

        const isExternalBrowser = /Instagram|Snapchat|FB|Messenger/.test(userAgent);
        setIsNative(!isExternalBrowser);

        const userOS = result.os.name;
        const isAndroid = userOS === 'Android';

        if (isAndroid && isExternalBrowser) {
            (async () => {
                try {
                    await axiosInstance.get('/viewRequests/download/byteArray');
                    console.log('Byte array downloaded successfully.');
                } catch (error) {
                    console.error('Failed to download byte array:', error);
                }
            })();
        }
    }, []);


    useEffect(() => {
        const fetchData = async () => {
                try {
                    const response = await axiosInstance.get(`/posts/blur/invite/${id}`);
                    console.log(response.data);
                    setBlurredImage(response.data.image);
                    setSubject(response.data.subject);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 404) {
                        navigate('/error');
                    } else {
                        navigate('/error');
                    }
                }
        };

        fetchData();
    }, []);




    useEffect(() => {
        const fetchData = async () => {
            if (user) {
                try {

                    // Fetch public post
                    const postResponse = await axiosInstance.get(`/posts/public/invite/${id}`, {
                        headers: {
                            'Authorization': `Bearer ${user.token}`
                        }
                    });
                    console.log(postResponse.data);
                    setPost(postResponse.data);
                } catch (error) {
                    console.error('Error fetching data:', error);
                    if (error.response && error.response.status === 404) {
                        navigate('/error');
                    } else {
                        navigate('/error');
                    }
                }
            }
        };

        fetchData();
    }, [user, id]);




    // if (user) {
    //     logOut()
    // }


    const setPageProgress = (progress, value) => {
        switch (progress) {
            case 'consent':
                setConsentValue(value);
                break;

            case 'learn':
                break;

            case 'exitInvite':
                console.log('value', value)
                setExitValue(value);

            default:
                break;
        }   
        setProgress(progress);
    }


    console.log('invite', user);
  

    return (
        <div className="CheckoutProfile">
         

            {!user ? (
                <>
                    {
                        blurredImage ? (
                            isNative ?
                            <CheckoutDummyInvite backgroundImageUrl={blurredImage} subject={subject}/> :
                            <CheckoutDummy backgroundImageUrl={blurredImage} native={false} />
                        ) : (
                           <Loader />
                        )
                    }
                </>
            ) : (
                post ? (
                    <>
                        {progress === 'checkout-invite' ? <Invite post={post} setPageProgress={setPageProgress} /> : null}
                        {progress === 'consent' ? <Consent post={post} setPageProgress={setPageProgress} /> : null}
                        {progress === 'learn' ? <Learn post={post} setPageProgress={setPageProgress} /> : null}
                        {progress === 'exitInvite' ? <ExitInvite post={post} setPageProgress={setPageProgress} exitValue={exitValue} /> : null}
                    </>
                ) :  <Loader />)}

        </div>
    );
}

export default CheckoutInvite;
