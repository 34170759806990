import React from 'react'
import { 
  Box, 
  Stack, 
  styled, 
  Typography,
} from '@mui/material'
import Link from '@mui/material/Link';
import FooterTitle from './FooterTitle'
import FooterLink from './FooterLink'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';

const Footer = () => {

  const StackColumn = styled(Stack) (() => ({
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flex: 1,
    gap: 8,
    textAlign: 'center',
  }));

  const BoxRow = styled(Box) (({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#000',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: 30,
    }
  }));

  return (
    
    <BoxRow 
    component = 'footer'
    sx={{
      py: 4,
      px: 2,
    }}
    >
      
      <StackColumn>
        <FooterTitle text={'our policies'} />
        <FooterLink text={'terms of service'} link='/tos'/>
        <FooterLink text={'privacy policy'} link='privacy' />
        <FooterLink text={'community guidelines'} link='communityguidelines' />
        <FooterLink text={'safety center'} link='safetycenter'/>
      </StackColumn>
      <StackColumn>
        <FooterTitle text={'support'} />
        <FooterLink text={'about product'} link='about' />
        <FooterLink text={'FAQs'} link='faq' />
        <FooterLink text={'contact us'}  mail='contact@checkoutmyfriend.link' />
      </StackColumn>

      <StackColumn>
        <FooterTitle text={'CheckoutMyFriend Socials'} />
        <Stack 
        direction='row' 
        width= '70px'
        maxWidth='100%'
        justifyContent='space-between'
        >
          <Link href="#" variant="body2" 
          sx={{
            color: '#414141',
            "&:hover": {
              color: '#1c2859',
            }
          }}
          >
            <InstagramIcon />  
          </Link> 
          <Link href="#"variant="body2" 
          sx={{
            color: '#414141',
            "&:hover": {
              color: '#1c2859',
            }
          }}
          >
            <FacebookIcon />
          </Link> 
        </Stack>
        <Typography 
        variant='caption'
        component='p' 
        >
          &copy; 2024 CheckoutMyFriend
        </Typography>
      </StackColumn>
    </BoxRow>
  )
}

export default Footer