import React from 'react';
import './About.css'; // Ensure you create a corresponding CSS file

const About = () => {
  return (
    <div className="about-container">
      <section className="hero">
        <h1 className="hero-title">Say Hello to "Checkout My Friend"!</h1>
        <p className="hero-subtitle">The ultimate wingman app that’s all about fun and connections.</p>
        <img src="/path/to/fun-image.png" alt="Fun Image" className="hero-image" />
      </section>

      <section className="how-it-works">
        <h2>How Does It Work?</h2>
        <div className="steps">
          <div className="step">
            <img src="/path/to/choose-pic-icon.png" alt="Choose Pic" />
            <p>Choose the hottest pic of your friend.</p>
          </div>
          <div className="step">
            <img src="/path/to/invite-icon.png" alt="Invite" />
            <p>Invite your friend to join the fun.</p>
          </div>
          <div className="step">
            <img src="/path/to/share-link-icon.png" alt="Share Link" />
            <p>Share the link and watch the magic happen.</p>
          </div>
        </div>
      </section>

      <section className="keeping-it-light">
        <h2>Keeping It Light</h2>
        <p>Pick from witty pick-up lines, flirty compliments, or fun icebreakers. It’s all about keeping things breezy!</p>
        <img src="/path/to/light-icon.png" alt="Light" className="keeping-light-image" />
      </section>

      <section className="mystery-of-the-prank">
        <h2>The Mystery of the Prank</h2>
        <p>Choose Prank to send a proposal that keeps everyone guessing—genuine or just a clever prank?</p>
        <img src="/path/to/prank-icon.png" alt="Prank" className="prank-image" />
      </section>

      <section className="vibe-check">
        <h2>Vibe Check</h2>
        <p>Discover the playful vibe of your friend with percentages showing how people are vibing with them.</p>
        <img src="/path/to/vibe-icon.png" alt="Vibe" className="vibe-image" />
      </section>

      <section className="call-to-action">
        <button className="cta-button">Get Started!</button>
      </section>
    </div>
  );
};

export default About;
