import React from 'react';

function Button({ backgroundColor, icon, onClick }) {
    return (
        <button
            style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: backgroundColor,
                border: 'none',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            onClick={onClick}
        >
            <i className="material-icons" style={{ color: '#FFF' }}>{icon}</i>
        </button>
    );
}

export default Button;
