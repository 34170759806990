import React from 'react'
import Header from '../Header/Header'

const AboutModal = () => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
           
            <Header page={'logo'} />

            <p style={{ color: '#c9c9c9', textAlign: 'center', fontSize: '1.2em', lineHeight: '1.5' }}>This is a fun light spirit game where one shares their best friend’s photo in their story. The Friend then get crazy votes like ‘Date’, ‘Marry’, or ‘Hangout’ from others.</p>
            <p style={{ color: '#c9c9c9', textAlign: 'center', marginTop: '10px', fontSize: '1.2em', lineHeight: '1.5' }}>In the end, you’ll get laughs and maybe bragging rights, because, you’ll see which tag you or your friend got the most.</p>

        </div>
    )
}

export default AboutModal