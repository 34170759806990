import React, { useState } from 'react';
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage';
import ImageContainer from '../../components/ImageContainer/ImageContainer';

import { FaBars, FaTimes } from 'react-icons/fa'; // Importing icons from react-icons
import Modal from '../../components/Modal/Modal';
import AboutModal from '../../components/AboutModal/AboutModal';
import ReportModal from '../../components/ReportModal/ReportModal';
import { icons } from '../../data/icons';
import { backgrounds } from '../../data/backgrounds';
import { useNavigate } from 'react-router-dom';

const Checkout = ({ post, setPageProgress }) => {
    const backgroundImageUrl = post?.image;
    const [menuOpen, setMenuOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modal, setModal] = useState('');
    const navigate = useNavigate();


    const onCheckoutClick = (checkoutValue) => {
        setPageProgress('what', checkoutValue.toLowerCase());
    };

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };


    const handleModal = (modal) => {
        if(modal !== 'privacy' && modal !== 'tos') {
            setShowModal(true);
            setMenuOpen(false);
            setModal(modal);
        } else{
           navigate(`/${modal}`);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setModal('');
    };

    const subjectPhotoBackground = backgrounds['date'];


    return (
        <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', flex: 1, height: '100vh' }}>
            <BackgroundImage imageUrl={backgrounds['checkout2']} />

            <div style={{ display: 'flex', alignSelf: 'center',justifyContent: 'center', position: 'relative', margin: 20, height: '65vh', width: '85vw', background: 'linear-gradient(to bottom, rgb(255, 37, 103) 0%, rgb(252, 112, 0) 81%)' ,alignItems: 'center', borderRadius: 20, 
             }}>
                <ImageContainer imageUrl={backgroundImageUrl}>
                    {/* Menu Icon */}
                    <div
                        onClick={toggleMenu}
                        style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            padding: '10px',
                            cursor: 'pointer',
                        }}
                    >
                        {menuOpen ? <FaTimes size={24} color="#FFF" /> : <FaBars size={24} color="#FFF" />}
                    </div>

                    <div style={{
                        position: 'absolute',
                        top: 10, left: 10,
                        padding: '10px',
                        cursor: 'pointer',
                        backgroundColor: '#FFF',
                        color: '#000',
                        borderRadius: '20px',
                         fontWeight: '700',
                         display: 'flex',
                         alignItems: 'center',
                         fontSize: '0.8em'
                    }}
                    onClick={() => handleModal('about')}
                    >
                        Just for Fun 😉
                    </div>
                    {/* Dropdown Menu */}
                    {menuOpen && (
                        <div
                            style={{
                                position: 'absolute',
                                top: 50,
                                right: 10,
                                backgroundColor: '#000',
                                borderRadius: '20px',
                                padding: '30px',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                zIndex: 10,
                            }}
                        >
                            <div style={{ width: '100%', borderBottom: '1px solid grey', paddingBottom: '10px', marginBottom: '5px', textAlign: 'center' }} onClick={() => handleModal('about')}>
                                <span style={{ color: '#FFF', fontWeight: 'bold', cursor: 'pointer' , fontSize: '1.5em'}}>About</span>
                            </div>
                            <div style={{ width: '100%', borderBottom: '1px solid grey', paddingBottom: '10px', marginBottom: '5px', textAlign: 'center' }} onClick={() => handleModal('privacy')}>
                                <span style={{ color: '#FFF', fontWeight: 'bold', cursor: 'pointer', fontSize: '1.5em' }}>Privacy</span>
                            </div>

                            <div style={{ width: '100%', borderBottom: '1px solid grey', paddingBottom: '10px', marginBottom: '5px', textAlign: 'center' }} onClick={() => handleModal('tos')}>
                                <span style={{ color: '#FFF', fontWeight: 'bold', cursor: 'pointer', fontSize: '1.5em' }}>Terms</span>
                            </div>
                            <div style={{ width: '100%', textAlign: 'center' }} onClick={() => handleModal('report')}>
                                <span style={{ color: 'red', fontWeight: 'bold', cursor: 'pointer', fontSize: '1.5em' }}>Report</span>
                            </div>
                        </div>
                    )}

                     <div style={{                     
                        padding: '10px',
                        cursor: 'pointer',
                        backgroundColor: '#FFF',
                        color: '#000',
                        borderRadius: '20px',
                        fontWeight: '700',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '1em',
                        position: 'absolute',
                        bottom:  95,
                        left: 20,
                    }}
                    
                    >
                        Sugar Mommy 😉
                    </div>


         
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            color: '#FFF',
                            backgroundColor: 'rgba(0, 0, 0, 0.1)',
                            borderRadius: '0 20px',
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start' // Align to the start
                        }}
                    >

             
                        
                        <h3 style={{ fontWeight: '950', fontSize: '1.6em', color: '#FFF' }}>{`${post?.createdBy.firstName}'s`} Friend 👻</h3>
                        <h3 style={{ fontSize: '1em', color: '#FFF', marginTop: '10px' }}>🤫 Vote to see the score 👇</h3>
                       
                    </div>
                </ImageContainer>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 10, marginRight: 5, marginLeft: 5 }}>
                {icons.map((icon, index) => (
                    <div
                        key={index}
                        onClick={() => onCheckoutClick(icon.title)}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '5px',
                            cursor: 'pointer',
                        }}
                    >
                        <img src={icon.image} alt={icon.title} style={{ width: '4em', height: '4em' }} />
                        <span style={{ color: '#FFF', marginTop: '5px', fontWeight: 'bold' }}>{icon.title}</span>
                    </div>
                ))}
            </div>

            <Modal show={showModal} handleClose={handleCloseModal}>
                {modal === 'about' ? (
                    <AboutModal />
                ) : null}
                {modal === 'report' ? (
                    <ReportModal />
                ) : null}
            </Modal>
        </div>
    );
};

export default Checkout;
