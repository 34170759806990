import React from 'react';
import './Faq.css';

const FAQPage = () => {
  return (
    <div className="faq-page">
      <section className="faq-section">
        <h2 className="faq-title">FAQ</h2>

        <div className="faq-item">
          <h3 className="faq-question">1. What age is "Checkout My Friend" suitable for?</h3>
          <p className="faq-answer">"Checkout My Friend" is suitable for users aged 18 and above.</p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">2. How do I invite my friend to join me in “Checkout My Friend”?</h3>
          <p className="faq-answer">
            To invite your friend to participate in 'Checkout My Friend,' follow these steps:
            <ol className="faq-steps">
              <li>Download and install the app on your device.</li>
              <li>Upload a photo of your friend that you wish to share.</li>
              <li>Once the photo is uploaded, use the provided option to send an invitation link to your friend through private communication channels, such as WhatsApp or SMS.</li>
              <li>Upon receiving the invitation link, your friend can view the photo and related context. They will then have the option to either join you in the app or decline and remove the post if they are not interested.</li>
            </ol>
            This ensures that your friend remains in control of their participation while enjoying a fun and engaging experience.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">3. What type of content is prohibited on the app?</h3>
          <p className="faq-answer">
            The following types of content are strictly prohibited on "Checkout My Friend":
            <ul className="faq-prohibited-list">
              <li>Content shared without the explicit consent of the person featured in the photo.</li>
              <li>Material involving nudity, violence, or any form of harassment or bullying.</li>
              <li>Content that promotes child abuse, illegal activities, or any unlawful or harmful behavior.</li>
              <li>Any exposure of personal, private, or sensitive data without permission.</li>
            </ul>
            For a detailed overview of our content guidelines, please refer to our Community Guidelines.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">4. I had a negative experience. How do I report it?</h3>
          <p className="faq-answer">
            If you had any negative experience with "Checkout My Friend", please report it to us at <a href="mailto:help@checkoutmyfriend.link" className="faq-email">help@checkoutmyfriend.link</a>. We take all reports seriously and will review yours promptly to assist you as best as we can.
          </p>
          <p className="faq-answer">
            If your negative experience involves inappropriate photographic content, you can report it by simply navigating to the menu option at the top right of the image, select 'Report,' and choose the reason for your report. Once submitted, we’ll flag the content as inappropriate and take the necessary action.
          </p>
          <p className="faq-answer">
            If your experience has been particularly distressing and you feel it's affecting your well-being, we encourage you to visit the safety and well-being services:
            <ul className="faq-safety-list">
              <li>AASRA (mental health and suicide prevention)</li>
              <li>DISC Foundation (online safety)</li>
              <li>SNEHA (health, sexuality, violence and other topics)</li>
            </ul>
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">5. How do I access or update my data?</h3>
          <p className="faq-answer">
            You may update certain aspects of your account information, such as your profile picture, through the settings section of the app. However, personal information such as your gender, name, Instagram Username (if added) and email address cannot be updated through the app once it has been set. These details can be accessed and you can request a copy of your data in a structured, commonly used, and machine-readable format.
          </p>
          <p className="faq-answer">
            If you require changes to this non-updateable information due to exceptional circumstances or want to access your data, please contact us at <a href="mailto:help@checkoutmyfriend.link" className="faq-email">help@checkoutmyfriend.link</a>. We will review your request and provide assistance as appropriate.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">6. What happens to my data after I delete my account?</h3>
          <p className="faq-answer">
            When you delete your account, all your data will be scheduled for permanent deletion from our servers after 30 days. This 30-day period is required for legal and security reasons, as it allows us to address any potential issues or legal inquiries that might arise. After this period, all your information will be fully erased to respect your privacy and comply with regulations. For a detailed overview of our data deletion policy, please refer to our Privacy Policy.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">7. What is consent and why is it required?</h3>
          <p className="faq-answer">
            Consent is the explicit permission given by a person to allow the use of their image or information. On "Checkout My Friend", we prioritize consent to ensure a safe and respectful environment for all users. Obtaining consent protects individuals’ privacy and upholds ethical standards, ensuring that everyone involved is comfortable and informed. This commitment to consent fosters a trusting and respectful community, where user privacy is respected and safeguarded.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">8. Why is gender required when signing up?</h3>
          <p className="faq-answer">
            We ask for your gender during sign-up to personalize your experience on the app. This information helps us tailor interactions and provide a more relevant and engaging experience, such as using appropriate pronouns and personalizing content. Rest assured, your gender information is kept private and secure, and we do not share it with other users or third parties, ensuring your privacy is always protected.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">9. Why do pranks exist in “Checkout My Friend”?</h3>
          <p className="faq-answer">
            Pranks in "Checkout My Friend" add a layer of mystery and excitement to the experience. These playful pranks are also sent by real people and are beautifully designed to look like real proposals, creating a fun challenge for you and your friends to figure out which are real. This playful element enhances the game's sense of curiosity and excitement, making it a more entertaining and memorable experience. It’s about keeping the spirit of the game alive, with surprises at every turn!
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">10. What is the difference between Free Checkout and Super Checkout?</h3>
          <p className="faq-answer">
            <strong>Free Checkout:</strong> While the Free Checkout is a great way to start, it limits how many users can checkout your friend. Once the limit is reached, your link will expire, and you won’t get any more likes or proposals.
          </p>
          <p className="faq-answer">
            <strong>Super Checkout:</strong> With Super Checkout, your posts last longer (till you want), allowing more people to engage over time. Moreover, you'll see who sent the proposals, and the pranks will be revealed, giving you a clearer picture of who's really interested in you or your friend.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">11. Why are there pre-filled messages?</h3>
          <p className="faq-answer">
            We've designed "Checkout My Friend" with your experience and safety in mind. The pre-filled messages are there to make interactions both seamless and secure. First, by using these curated one-liners, we eliminate the risk of negative behavior like bullying or trolling, ensuring that everyone has a positive and respectful experience.
          </p>
          <p className="faq-answer">
            Second, we know that not everyone wants to spend time crafting the perfect message. Our one-liners make it quick and easy to express interest, so you can enjoy the app without any hassle. Just pick a message that feels right and go!
          </p>
          <p className="faq-answer">
            Lastly, while safety is our top priority, we’ve made sure these messages are still fun and engaging. They’re designed to be playful, witty, and a little cheeky, so you can have a good time without compromising on respect or enjoyment.
          </p>
        </div>

        <div className="faq-item">
          <h3 className="faq-question">12. Why is the app free but offers in-app purchases?</h3>
          <p className="faq-answer">
            "Checkout My Friend" is available for free to allow everyone to experience its core features without any cost. The in-app purchases are designed to enhance the user experience with additional features and benefits. These purchases are optional and provide extra functionality and customization for those who want to elevate their experience further. By offering both free and paid options, we aim to ensure that the app remains accessible while providing value for those who choose to invest in premium features.
          </p>
        </div>
      </section>
    </div>
  );
};

export default FAQPage;
