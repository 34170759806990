import React from 'react'
import './CircularImage.css'

const CircularImage = ({ backgroundImageUrl, icon }) => {


    return (
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <div className="circular-border-container"> 
                <div className="circular-border-image" style={{ backgroundImage: `url(${backgroundImageUrl})` }}>
                  
                </div>
               {icon &&  <img src={icon} alt="Love Icon" className="love-icon" width={'30px'} height={'30px'} />}
            </div>
        </div>
    )
}

export default CircularImage