import React from 'react';
import './Tos.css';

const TermsOfService = () => {
  return (
    <div className="terms-container">
      <header className="terms-header">
        <h1>Terms of Service of “Checkout My Friend”</h1>
        <p className="last-updated">Last Updated: August 28th 2024</p>
      </header>

      <section className="terms-notice">
        <p>
          <strong>IMPORTANT NOTICE:</strong> Please read these Terms of Service ("Terms") carefully before using the “Checkout My Friend” App ("CMF," "App," "we," "us," or "our"). These Terms govern your access to and use of the App and the services provided therein ("Services"). By accessing or using the App, you agree to be bound by these Terms. If you do not agree to these Terms, you must not use the App.
        </p>
        <p>
          You should note that by using “Checkout My Friend” ("App"), you acknowledge that while “Checkout My Friend” ("App") functions within the domain of Social Networking due to the interactive nature of content sharing and response mechanisms, it is important to recognize that the App is primarily designed as an engagement tool to enhance social experiences and interactions. The App does not operate as a conventional social networking platform (which typically includes a social graph and various means of persistent connections between users); it serves as a conduit that allows users to share content and interact in a structured environment, emphasizing engagement and enjoyment rather than creating or maintaining ongoing social connections.
        </p>
        <p>
          Despite this distinction, the App is committed to upholding the highest standards of safety, privacy, and user experience. The App employs comprehensive measures, including but not limited to, content moderation protocols, user consent mechanisms, and community guidelines enforcement, to ensure that all interactions facilitated through the App are respectful, safe, and aligned with legal and ethical standards. These measures are in place to protect all parties involved and to maintain the integrity and fun atmosphere the App aims to provide.
        </p>
      </section>

      <section className="terms-content">
        <h2>1. Definitions</h2>
        <p>For the purposes of these Terms, the following terms shall have the meanings defined below:</p>
        <ul>
          <li><strong>"App" or "CMF":</strong> Refers to the “Checkout My Friend” mobile Application and any associated websites, APIs, and services provided by Extasy Technologies.</li>
          <li><strong>"User":</strong> Any person who downloads, accesses, or uses the App.</li>
          <li><strong>"Initiator":</strong> The User who uploads and shares a friend’s photo within the App, initiating interactions and responses from other Users.</li>
          <li><strong>"Friend":</strong> The person whose photo is uploaded by the Initiator for sharing within their social circles.</li>
          <li><strong>"Viewers":</strong> Individuals who view the Friend's photo in web browsers via shared link by the Initiator.</li>
          <li><strong>"You/Your":</strong> Refers to the User of the App, whether as an Initiator, Friend, or any other participant in the App's ecosystem.</li>
          <li><strong>"Post":</strong> Any photo of a Friend shared by the Initiator within the App, accessible by the Viewers through a link.</li>
          <li><strong>"Likes":</strong> Any response, including but not limited to Date, Marry, Hookup, or Prank options along with pre-written messages, provided by Users or the Viewers to a Post.</li>
          <li><strong>“Sender”:</strong> Individuals who interact with the Friend’s photo via shared link in the web browsers by sending a Like.</li>
          <li><strong>"Inbox":</strong> The section within the App where all Likes and related interactions are collected and displayed for the Initiator and the Friend.</li>
          <li><strong>"Subscription/Super Checkout":</strong> A paid service that offers Users additional features such as identifying who sent specific Likes, seeing Pranks, and extending the lifespan of a Post.</li>
          <li><strong>"Account":</strong> A User’s personal profile created upon signing up for the App.</li>
        </ul>

        <h2>2. Agreement to Terms</h2>
        <p>
          By accessing or using the App, you confirm that you have read, understood, and agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, you must cease using the App immediately.
        </p>
        <p>
          These Terms constitute a binding legal agreement between you and Extasy Technologies. We reserve the right to modify these Terms at any time. Any changes will be effective immediately upon posting, and your continued use of the App after such changes constitutes your acceptance of the updated Terms.
        </p>

        <h2>3. Eligibility</h2>
        <p>
          To use the App, you must be at least 18 years old or the age of majority in your jurisdiction, whichever is higher. By using the App, you represent and warrant that you have the legal capacity to enter into a binding agreement. If you do not meet these requirements, you must not access or use the App.
        </p>

        <h2>4. Account Registration and Security</h2>
        <h3>4.1 Account Creation:</h3>
        <p>
          To use the App, you must register for an Account. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete. You are responsible for safeguarding your password and any other credentials used to access your Account. You agree not to disclose your password to any third party and to take sole responsibility for any activities or actions under your Account, whether or not you have authorized such activities or actions.
        </p>

        <h3>4.2 Account Responsibility:</h3>
        <p>
          You are responsible for maintaining the confidentiality of your login credentials and are fully responsible for all activities that occur under your Account. You agree to notify us immediately of any unauthorized use of your Account or any other breach of security. We cannot and will not be liable for any loss or damage arising from your failure to comply with the above requirements.
        </p>

        <h3>4.3 Account Termination:</h3>
        <p>
          We reserve the right to terminate or suspend your Account at any time, with or without notice, for any reason, including but not limited to, a violation of these Terms. Upon termination, your right to use the App will immediately cease. You are solely responsible for all data, Post, and activity associated with your Account, and we shall not be liable for any loss of such data or Post following termination.
        </p>

        <h2>5. User Obligations</h2>
        <h3>5.1 Compliance with Laws:</h3>
        <p>
          You agree to use the App in compliance with all Applicable local, state, national, and international laws and regulations. You are solely responsible for your conduct while using the App and the Post you submit, or share.
        </p>

        <h3>5.2 Consent and Post Deletion Rights:</h3>
        <p>
          As an Initiator, you represent and warrant that you have obtained explicit, clear, and informed consent from your Friend before uploading and sharing their photo on the App. You must ensure that your Friend fully understands how their photo will be used and the potential consequences. Failure to obtain such consent may result in account suspension or termination, as well as potential legal action. Moreover, Initiator acknowledges that their Friend has the right to request the deletion of the Post at any time. If a Friend chooses to delete the Post, the Initiator who shared it agrees not to contest this decision. The App reserves the right to remove any Post upon the Friend’s request, and the Initiator acknowledges that the shared link will become inactive. This process ensures that User rights and privacy are respected.
        </p>

        <h3>5.3 Post Guidelines:</h3>
        <p>
          You agree not to upload, or share any Post that:
        </p>
        <ul>
          <li>Contains nudity, violence, child abuse, illegal activities, or any other unlawful material.</li>
          <li>Infringes on any third party's intellectual property rights or violates any privacy rights.</li>
          <li>Is defamatory, discriminatory, or otherwise harmful to others.</li>
        </ul>
        <p>
          You acknowledge that violating these guidelines may result in the removal of the Post, account suspension, termination or potential legal actions. See our Community Guidelines to learn more.
        </p>

        <h3>5.4 Sharing and Inviting Guidelines:</h3>
        <p>Initiators must share the link in their personal spaces, such as their personal social media accounts (e.g., Instagram Stories). Initiators agree not to redistribute the link to third parties or allow others to share it on their behalf. This ensures that the shared Post remains within the intended audience and minimizes the risk of unauthorized distribution. Initiators are responsible for sending the invitation link directly to the Friend. It is the Initiator's responsibility to ensure that the invitation is not mistakenly sent to someone else. Failure to do so may result in Account suspension or termination. This measure is essential to maintaining trust and respecting the privacy of individuals featured in shared Post.</p>

        <section className="terms-content">
        <h2>6. Post Ownership and Licensing</h2>
        <h3>6.1 Initiator and Friend Consent</h3>
        <p>
          When an Initiator uploads Post featuring a Friend on the App, the Initiator is responsible for obtaining explicit consent from the Friend prior to uploading. Once the Post is uploaded, the Initiator must send an invitation link to the Friend. By accepting this invitation link, the Friend grants Checkout My Friend (CMF) a non-exclusive, worldwide, royalty-free, fully paid, transferable, sublicensable license to use, reproduce, distribute, prepare derivative works of, display, and perform the Post in connection with the App and CMF's business, including but not limited to promoting and redistributing part or all of the App.
        </p>
        <h3>6.2 Conditional Ownership and Consent</h3>
        <p>
          The Friend retains ownership of all intellectual property rights in the Post uploaded by the Initiator. However, the Friend acknowledges that by accepting the invitation link, CMF may use the Post as described in these Terms without any further consent, notice, or compensation to the Friend or the Initiator.
        </p>
        <h3>6.3 Third-Party Post</h3>
        <p>
          The App may include Post provided by third parties, including other Users. Such Post is the sole responsibility of the entity that makes it available. CMF is not responsible for any third-party Post, including its accuracy, completeness, or legality.
        </p>

        <h2>7. Privacy and Data Protection</h2>
        <h3>7.1 Data Collection</h3>
        <p>
          We collect, store, and use your personal data in accordance with our Privacy Policy, which forms part of these Terms. By using the App, you agree to the collection, storage, and use of your personal data as outlined in the Privacy Policy.
        </p>
        <h3>7.2 Data Sharing with Premium Subscribers</h3>
        <p>
          Users consent to the sharing of their name and profile picture (if available) with other Users on the App who have purchased a premium subscription. This information is shared as part of the App's premium service, which allows subscribers to identify individuals who interact with them through the App. The sharing of this data is designed to enhance the User experience by fostering a more connected and interactive environment.
        </p>
        <h3>7.3 Data Deletion</h3>
        <p>
          In the event of account deletion by either the Initiator or the Friend, all associated Post will be removed from the App immediately. Both parties have the ability to delete Post at any time. If either party deletes the Post or their account, the Post will be removed from the App and all associated platforms. Upon account deletion, your data will be retained on our servers for a period of 30 days to address any potential legal issues or disputes that may arise. After this retention period, all data will be permanently deleted from our servers. This retention policy is in place to protect the interests of all parties involved.
        </p>
        <h3>7.4 User Rights</h3>
        <p>
          You have the right to request access to your personal data, correction of inaccurate data, or deletion of your data. To exercise these rights, please contact us at help@checkoutmyfriend.link.
        </p>

        <h2>8. Post Moderation and Reporting</h2>
        <h3>8.1 Post Moderation</h3>
        <p>
          We reserve the right to monitor and review all Post submitted to the App to ensure compliance with these Terms. We may, at our sole discretion, remove or disable access to any Post that violates these Terms or is otherwise objectionable.
        </p>
        <h3>8.2 Reporting Violations</h3>
        <p>
          If you encounter Post that you believe violates these Terms, you may report it to us using the reporting feature on the web. You can navigate to the “Report” by locating the menu option at the top-right of the image you are supposed to report. Upon receiving a report, our team will conduct a thorough review and may remove the Post or take other appropriate action, including reporting the incident to law enforcement if necessary.
        </p>
        <h3>8.3 Consequences of Violations</h3>
        <p>
          Users who violate these Terms may face consequences including, but not limited to, Post removal, account suspension, or termination. In cases of severe or repeated violations, we may report the User to relevant law enforcement authorities.
        </p>

        <h2>9. Prank Acknowledgement</h2>
        <h3>9.1. Prank Feature and User Acknowledgment</h3>
        <p>
          CMF includes a Prank feature designed to add a playful element to User interactions. This adds a layer of mystery and excitement for Initiators and Friends. These pranks are harmless and are purely meant for fun. It keeps everyone guessing, adding unpredictability and fun to the experience. By signing up for the App, Users acknowledge and consent to the possibility that some interactions, such as responses to the date, marry, and hookup options, may be intended as Pranks rather than respective expressions of interest. This feature is integral to the App's design and is intended to provide a lighthearted and entertaining User experience.
        </p>
        <h3>9.2. User Consent to Prank Feature</h3>
        <p>
          By using the CMF, Users agree to participate in the Prank feature and accept that it is a fundamental aspect of the App's design. Users acknowledge that they will not hold the App or its developers liable for any misunderstandings or unexpected outcomes resulting from the Prank feature, including any emotional or social consequences arising from Prank interactions.
        </p>
        <h3>9.3. Limitations and User Responsibility</h3>
        <p>
          While the Prank feature is designed for entertainment, Users are encouraged to engage respectfully and responsibly. Any misuse of the feature that leads to harassment, bullying, or violation of App guidelines may result in corrective actions, including account suspension or termination. The App reserves the right to take necessary actions to protect the community and ensure a safe and respectful User environment.
        </p>

        <h2>10. Subscription Services</h2>
        <h3>10.1 Subscription Features</h3>
        <p>
          Our App may offer subscription plans that enhance the User experience by providing additional features. These features include, but are not limited to:
        </p>
        <ul>
          <li>Visibility of Senders: Subscribers can see who sent specific Likes, including their name, profile picture, and the exact time the Like was sent.</li>
          <li>Prank Detection: Subscribers have the ability to identify which Likes fall under Pranks, adding a layer of transparency and engagement.</li>
          <li>Extended Post Lifespan: Free Users are limited to a maximum of 30 Viewers per Post and a lifespan of 24 hours. Subscribers, however, can extend the Post’s lifespan indefinitely, subject to ongoing subscription renewal, and can accommodate up to 1,000 Viewers per Post every week.</li>
        </ul>
        <h3>10.2 Subscription Terms</h3>
        <p>
          Subscriptions are offered on a weekly and monthly basis and automatically renewed at the end of each subscription period unless you cancel your subscription at least 24 hours before the end of the current period. You may manage your subscription and turn off auto-renewal by accessing your Account settings.
        </p>
        <h3>10.3 Payment and Billing</h3>
        <p>
          <strong>Payment Method:</strong> By subscribing, you authorize us to charge the Applicable subscription fees to the payment method you provide during the registration process.
        </p>
        <p>
          <strong>Billing:</strong> Subscription fees will be billed automatically at the start of the subscription period. If a payment is not successfully settled due to expiration, insufficient funds, or other reasons, and you do not cancel your subscription, we may suspend your access to the subscription services until we have successfully charged a valid payment method.
        </p>
        <p>
          <strong>Refunds:</strong> All payments are non-refundable except as required by law. You acknowledge that by subscribing, you waive your right to a refund for any subscription fees already paid.
        </p>
        <p>
          <strong>Payment Issues:</strong> If you have any payment related issues with In-app Subscription, then you need to contact the Application Store directly.
        </p>


        <h3>10.4 Post-Level Subscription Transfer:</h3>


        <p>Subscriptions within the App are Post-specific and not tied to individual accounts. Each subscription purchased Applies solely to the specific Post associated with it. Users must purchase a separate subscription for each piece of Post they wish to access with premium features. This means that subscribing to one piece of Post does not grant access to premium features on other Post or extend to the entire account. 
In cases where a subscription is applied to a specific Post involving two parties (the Initiator and the Friend), the premium features are accessible to both parties. If either the Initiator or the Friend subscribes to the Post, both individuals associated with that Post will benefit from the premium services, ensuring mutual access to the enhanced features. The benefits of a subscription are confined to the specific Post for which it was purchased and do not extend to any other Post or Users not associated with the subscribed Post. Users must understand and acknowledge that each subscription is independent and solely Applicable to the individual Post for which it was purchased.
</p>
      </section>


      <section>
        <h2>11. Prohibited Conduct</h2>
        <h3>11.1. User Responsibilities and Conduct Control</h3>
        <p>
          Users who gain access to the identities of Senders through premium or paid features of the Platform agree to uphold the principles of confidentiality and respect towards all participants. The Platform is designed for entertainment purposes, and interactions are intended to be conducted in a non-serious and lighthearted manner.
        </p>
        <h3>11.2. User Prohibition</h3>
        <p>
          Users are expressly prohibited from engaging in any conduct that may cause discomfort, harassment, or distress to other Users, including but not limited to stalking, persistent unwanted communication, or any behavior deemed inappropriate. Such conduct includes, without limitation, actions that violate personal boundaries or create an unsafe environment for any User.
        </p>
        <h3>11.3. Consequences of Misconduct</h3>
        <p>
          Breach of these terms, including any form of harassment or boundary violation, may result in the immediate suspension or termination of access to the Platform. The Platform reserves the right to pursue further legal action in response to any violations that compromise the safety or well-being of its Users.
        </p>
        <h3>11.4. Reporting Mechanisms</h3>
        <p>
          Users are encouraged to report any inappropriate conduct or violations of these terms to the Platform's support team at help@checkoutmyfriend.like for immediate review and action. The Platform is committed to investigating all reported incidents and taking appropriate measures to ensure a safe and respectful User experience.
        </p>
      </section>

      <section>
        <h2>12. Limitation of Liability</h2>
        <h3>12.1. Disclaimer of Warranties</h3>
        <p>
          The App and all services provided by CMF are offered "as is" and "as available" without warranties of any kind, either express or implied. To the fullest extent permitted by law, we disclaim all warranties, including but not limited to:
        </p>
        <p>
          <strong>Fitness for a Particular Purpose:</strong> We do not warrant that the App will meet your specific requirements or that it will be uninterrupted, error-free, or secure.
        </p>
        <p>
          <strong>Accuracy of Information:</strong> We do not warrant the accuracy, reliability, or completeness of any information provided through the App.
        </p>
        <p>
          <strong>Third-Party Post:</strong> We are not responsible for any third-party Post or materials, including their legality, accuracy, or availability.
        </p>
        <h3>12.2. Limitation of Liability</h3>
        <p>
          To the maximum extent permitted by law, CMF, its affiliates, partners, officers, directors, employees, and agents shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits, revenue, data, or use, arising out of or related to your use of or inability to use the App, even if we have been advised of the possibility of such damages. Our total liability to you for any claims arising out of or related to the App shall not exceed the amount you paid us, if any, during the three (3) months prior to the event giving rise to the claim.
        </p>
        <h3>12.3. Exclusions and Limitations</h3>
        <p>
          Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations may not apply to you. In such cases, our liability will be limited to the fullest extent permitted by applicable law.
        </p>
      </section>

      <section>
        <h2>13. Dispute Resolution</h2>
        <h3>13.1. Governing Law</h3>
        <p>
          These Terms shall be governed by and construed in accordance with the laws of the Republic of India, without regard to its conflict of law principles.
        </p>
        <h3>13.2. Informal Resolution</h3>
        <p>
          Before initiating any legal action, You agree to first contact us at legal@checkoutmyfriend.link to attempt to resolve the dispute informally. We will attempt to resolve the dispute through communication and negotiation in good faith within 90 days of Your notice.
        </p>
        <h3>13.3. Arbitration</h3>
        <p>
          If the dispute cannot be resolved informally, it shall be finally resolved by binding arbitration. The arbitration shall be conducted in accordance with the Indian Arbitration and Conciliation Act, 1996, as amended, and the venue of arbitration shall be Guwahati, Assam, India. The language of the arbitration shall be English. The arbitrator’s decision shall be final and binding, and judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
        </p>
        <h3>13.4. Waiver of Class Action Rights</h3>
        <p>
          You agree that any disputes arising under these Terms or in connection with the App shall be resolved on an individual basis and not as part of any class, collective, or representative action. You expressly waive your right to participate in any class action or collective lawsuit.
        </p>
        <h3>13.5. Injunctive Relief</h3>
        <p>
          Nothing in these Terms shall prevent either party from seeking injunctive or other equitable relief in a court of competent jurisdiction to prevent the actual or threatened infringement, misappropriation, or violation of a party’s intellectual property rights or other proprietary rights.
        </p>
      </section>

      <section>
        <h2>14. Intellectual Property Rights</h2>
        <h3>14.1. CMF Intellectual Property</h3>
        <p>
          All intellectual property rights in and to the App, including but not limited to the software, design, text, graphics, logos, and other materials, are owned by CMF or its licensors. These rights are protected by copyright, trademark, and other intellectual property laws. You acknowledge that you have no rights in or to the App except as expressly provided in these Terms.
        </p>
        <h3>14.2. License to Use the App</h3>
        <p>
          Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, non-sublicensable license to download, install, and use the App on your personal mobile device for your personal, non-commercial use. This license is revocable at any time without notice or liability to you.
        </p>
        <h3>14.3. Restrictions</h3>
        <p>
          You agree not to:
        </p>
        <ul>
          <li>Reproduce, distribute, publicly display, or create derivative works based on the App or any part thereof without our prior written consent.</li>
          <li>Reverse engineer, decompile, or disassemble any part of the App, except and only to the extent that such activity is expressly permitted by applicable law.</li>
          <li>Use the App for any commercial purposes without our prior written consent.</li>
          <li>Remove, alter, or obscure any copyright, trademark, or other proprietary rights notices on or in the App.</li>
        </ul>
      </section>

      <section>
        <h2>15. Termination</h2>
        <h3>15.1. Termination by CMF</h3>
        <p>
          We may terminate or suspend your Account or access to the App at any time, without notice or liability, if you breach these Terms or for any other reason, including but not limited to prolonged inactivity, fraudulent activity, or illegal conduct.
        </p>
        <h3>15.2. Termination by You</h3>
        <p>
          You may terminate your Account at any time by following the instructions provided in the App. Upon termination, you must cease all use of the App and delete all copies of the App from your devices.
        </p>
        <h3>15.3. Effect of Termination</h3>
        <p>
          Upon termination of your Account for any reason, all licenses and rights granted to you under these Terms will immediately cease. Any provisions of these Terms that by their nature should survive termination shall survive, including but not limited to, ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
        </p>
      </section>

      <section>
        <h2>16. Data Protection and Privacy</h2>
        <h3>16.1. Data Collection</h3>
        <p>
          CMF collects and processes personal data in accordance with our Privacy Policy. The types of data we collect include, but are not limited to:
        </p>
        <ul>
          <li><strong>Personal Information:</strong> Name, email address, gender, Instagram Username and profile photo.</li>
          <li><strong>Usage Data:</strong> Interaction history, App usage patterns, and device information.</li>
          <li><strong>Post Data:</strong> Photos, Tags, Secret, Likes, and pre-written messages shared within the App.</li>
        </ul>
        <h3>16.2. Data Retention</h3>
        <p>
          We retain personal data for as long as necessary to fulfill the purposes for which it was collected, or as required by law. If you delete your Account, we will remove your data from the App, but it will be retained on our servers for up to 30 days to allow for any necessary legal or operational reasons before it is permanently deleted.
        </p>
        <h3>16.3. User Rights</h3>
        <p>
          You have the following rights concerning your personal data:
        </p>
        <ul>
          <li><strong>Access:</strong> You can request a copy of your data that we hold.</li>
          <li><strong>Rectification:</strong> You can request the correction of any inaccurate or incomplete data.</li>
          <li><strong>Deletion:</strong> You can request the deletion of your data, subject to legal and operational requirements.</li>
          <li><strong>Objection:</strong> You can object to the processing of your data under certain circumstances.</li>
          <li><strong>Data Portability:</strong> You can request the transfer of your data to another service provider.</li>
        </ul>
        <h3>16.4. Data Security</h3>
        <p>
          We implement appropriate technical and organizational measures to protect personal data from unauthorized access, disclosure, alteration, or destruction. However, no internet-based service can be completely secure, and we cannot guarantee the absolute security of your data.
        </p>
        <h3>16.5. Third-Party Services</h3>
        <p>
          CMF may use third-party service providers to facilitate certain aspects of the App, such as payment processing, hosting, and analytics. These providers may have access to your personal data only to perform specific tasks on our behalf and are obligated not to disclose or use it for any other purpose.
        </p>
        <h3>16.6. Data Transfers</h3>
        <p>
          By using the App, you consent to the transfer of your personal data to countries outside of your residence, including countries that may have different data protection laws than your own. We will ensure that any such transfers comply with applicable data protection regulations.
        </p>
        <h3>16.7. Children’s Privacy</h3>
        <p>
          The App is not intended for use by individuals under the age of 18. We do not knowingly collect personal data from children under 18. If we become aware that a child under 18 has provided us with personal data, we will take steps to delete such data.
        </p>
      </section>

      <section>
        <h2>17. Indemnification</h2>
        <h3>17.1. Your Indemnification Obligations</h3>
        <p>
          You agree to indemnify, defend, and hold harmless CMF, its affiliates, partners, officers, directors, employees, and agents from and against any and all claims, damages, losses, liabilities, costs, and expenses (including reasonable attorneys' fees) arising out of or related to:
        </p>
        <ul>
          <li>Your use of the App or any Post you share through the App.</li>
          <li>Your violation of these Terms or any applicable law.</li>
          <li>Any claim that your Post infringes or violates the intellectual property, privacy, or other rights of any third party.</li>
          <li>Your gross negligence or willful misconduct.</li>
        </ul>
        <h3>17.2. CMF's Right to Defense</h3>
        <p>
          CMF reserves the right, at its own expense, to assume the exclusive defense and control of any matter subject to indemnification by you, in which event you agree to cooperate with us in asserting any available defenses.
        </p>
      </section>

      <section>
        <h2>18. Force Majeure</h2>
        <h3>18.1 Force Majeure Events</h3>
        <p>
          CMF shall not be liable for any failure or delay in performance of its obligations under these Terms due to circumstances beyond its reasonable control, including but not limited to:
        </p>
        <ul>
          <li>Acts of God, natural disasters, or extreme weather conditions.</li>
          <li>War, invasion, terrorist attacks, or civil unrest.</li>
          <li>Governmental orders, regulations, or restrictions.</li>
          <li>Strikes, labor disputes, or industrial action.</li>
          <li>Internet or telecommunications failures, power outages, or system failures.</li>
        </ul>
        <h3>18.2 Effect of Force Majeure</h3>
        <p>
          If a force majeure event occurs, CMF's obligations under these Terms will be suspended for the duration of the event. We will use reasonable efforts to mitigate the impact of the force majeure event and resume normal operations as soon as practicable.
        </p>
      </section>

      <section>
        <h2>19. Miscellaneous Provisions</h2>
        <h3>19.1 Entire Agreement</h3>
        <p>
          These Terms, together with our Privacy Policy and Community Guidelines constitute the entire agreement between you and CMF concerning your use of the App and supersede all prior agreements, understandings, or communications between you and CMF regarding the subject matter herein.
        </p>
        <h3>19.2 Amendments</h3>
        <p>
          CMF reserves the right to modify these Terms at any time. Any changes will be effective immediately upon posting the revised Terms within the App or on our website. Your continued use of the App following the posting of changes constitutes your acceptance of such changes.
        </p>
        <h3>19.3 Severability</h3>
        <p>
          If any provision of these Terms is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions will remain in full force and effect. The invalid or unenforceable provision will be deemed modified to the extent necessary to make it valid and enforceable.
        </p>
        <h3>19.4 Waiver</h3>
        <p>
          No waiver of any term or condition of these Terms shall be deemed a further or continuing waiver of such term or condition or any other term or condition, and any failure to assert a right or provision under these Terms shall not constitute a waiver of such right or provision.
        </p>
        <h3>19.5 Assignment</h3>
        <p>
          You may not assign or transfer these Terms, or any rights or obligations hereunder, without our prior written consent. CMF may freely assign or transfer these Terms without restriction.
        </p>
        <h3>19.6 Notices</h3>
        <p>
          All notices, requests, or other communications required or permitted to be given under these Terms shall be in writing and delivered to CMF by email at legal@checkoutmyfriend.link.
        </p>
        <h3>19.7 Headings</h3>
        <p>
          The section headings in these Terms are for convenience only and have no legal or contractual effect.
        </p>
      </section>

      <section>
        <h2>20. Contact Information</h2>
        <p>
          If you have any questions about these Terms, please contact us at:
        </p>
        <ul>
          <li><strong>Official Communications:</strong> legal@checkoutmyfriend.link</li>
          <li><strong>Help Center:</strong> help@checkoutmyfriend.link</li>
          <li><strong>Law Enforcement Inquiries:</strong> legal@checkoutmyfriend.link</li>
        </ul>
      </section>

      </section>
    </div>
  )}


export default TermsOfService;