import React from 'react';
import { Box, styled, Typography, useMediaQuery, useTheme } from "@mui/material";
// Images for the app store logos
import appStoreLogo from '../assets/app-store-logo.png';
import googlePlayLogo from '../assets/google-play-logo.png';
// Hero image and landing text
import backgroundImg from '../assets/landing-bac-2.png';
import backgroundMobileImg from '../assets/landing-bac-mobile.png';
import landingTextImg from '../assets/landing-text.png';
import landingTextImgSmall from '../assets/landing-text-mobile.png';

const Header = () => {
    const theme = useTheme();
    const isMediumScreen = useMediaQuery(theme.breakpoints.down('md')); // Check for md breakpoint

    const CustomBox = styled(Box)(({ theme }) => ({
        minHeight: '80vh',
        display: 'flex',
        gap: theme.spacing(2),
        paddingTop: theme.spacing(10),
        background: `url(${backgroundImg})`,  // Set background image
        backgroundSize: 'cover',  // Cover entire background
        backgroundPosition: 'center',  // Position background in center
        backgroundRepeat: 'no-repeat',  // Prevent the background from repeating
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            alignItems: 'center',
            background: `url(${backgroundMobileImg})`, 
            backgroundSize: 'cover',  // Cover entire background
            backgroundPosition: 'center',  // Position background in center
            backgroundRepeat: 'no-repeat',  // Prevent the background from repeating
            textAlign: 'center',
        }
    }));

    const ImageContainer = styled(Box)(({ theme }) => ({
        width: '25vw', // Adjust as needed to fit the image size
        paddingLeft: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            flex: '2',
            textAlign: 'center',
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            width: '70vw'
        },
    }));

    const StoreLogos = styled(Box)(({ theme }) => ({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start', // Align logos to flex-start
        justifyContent: 'flex-start',
        gap: theme.spacing(2), // Space between images
        marginTop: theme.spacing(5),
        [theme.breakpoints.down('md')]: {
            alignItems: 'center', // Center logos on mobile
        }
    }));

    return (
        <CustomBox component='header'>
            {/* Image Container */}
            <ImageContainer component='section'>
                <img
                    src={isMediumScreen ? landingTextImgSmall : landingTextImg}
                    alt="Landing Text"
                    style={{ width: '100%' , marginTop: isMediumScreen ? '-50px' : 0 }}  // Adjust image size to fit the container
                />

                {/* Store logos */}
                <StoreLogos>
                    <img
                        src={appStoreLogo}
                        alt="Download on the App Store"
                        style={{ width: isMediumScreen ? '150px' : '150px' }} // Adjust image size
                    />
                    <img
                        src={googlePlayLogo}
                        alt="Get it on Google Play"
                        style={{ width: isMediumScreen ? '150px' : '150px' }} // Adjust image size
                    />
                </StoreLogos>
            </ImageContainer>
        </CustomBox>
    );
}

export default Header;
