import React, { useState } from 'react'
import './Header.css'
import { pageTitles } from '../../data/DummyValues'


const Header = ({ page }) => {
    const [headerImage, setHeaderImage] = useState(pageTitles[page]);

    return (
        <div className='header'>

            <img src={headerImage} className='header-image' />

        </div>
    )
}

export default Header