import React, { useContext, useState } from 'react';


const ConsentModal = ({handleCloseModal, removePost}) => {

    return (
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' , width: '100%'}}>
            <h2 style={{ color: '#FF0834', fontWeight: 'bolder', fontSize: '2em', marginBottom: '10px'}}>Warning!</h2>


            <p
             style={{
                color: '#FFFFFF',
                textAlign: 'center',
                lineHeight: '2',
                fontWeight:'600',
                fontSize: '1.2em',
                marginBottom: '10px'
             }}
            >
                Removing will delete this post and photo. You won't get any proposals and be part of this game. Do you still want to delete it?
            </p>


            <div 
            onClick={handleCloseModal}
            style={{width: '80%', padding: '20px', backgroundColor: '#fff', color:'#000', borderRadius: '50px', textAlign: 'center', fontSize: '1.2em', fontWeight: '700', marginBottom: '15px'}}>
                No, I want to join
            </div>


            <div 
            onClick={removePost}
            style={{width: '80%', padding: '20px', backgroundColor: '#340000', color:'#FF2734', borderRadius: '50px', textAlign: 'center', fontSize: '1.2em', fontWeight: '700', border: '1px solid #FF0000'}}>
                Yes, delete it
            </div>
            
        </div>
    );
};

export default ConsentModal;
