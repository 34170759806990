import React from 'react'
import BackgroundImage from '../../components/BackgroundImage/BackgroundImage'

const Error = ({}) => {
  return (
    <div style={{display: 'flex', flexDirection: 'column', height: '100vh', justifyContent: 'space-evenly', alignItems: 'center'}}>
        <BackgroundImage imageUrl={''} />
            <p className="exit-message">
                                    The  <span className="highlight">Page</span> you are looking for no more <span className="highlight">exists!</span>
                                </p>


                                <p className="exit-message" style={{ marginTop: '30px' }}>
                                    <span className="highlight">Imagine</span> how many dates your friends will get?
                                </p>
                                <button className="exit-button">
                                    Time to get your friends checked out
                                </button>

                                <p className="exit-subtext">
                            <span className="highlight">4000+</span>  friends got checked out now
                        </p>
    </div>
  )
}

export default Error