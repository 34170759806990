// src/services/axios.js

import axios from 'axios';

// Create an Axios instance with default settings
const axiosInstance = axios.create({
    baseURL: 'https://ccheckoutmyfriend-4962b241527d.herokuapp.com/v1/api/',
    // baseURL: 'http://localhost:3000/v1/api/',
    headers: {
        'Content-Type': 'application/json',
    },
});

export default axiosInstance;
