import React from 'react';

function ImageContainer({ imageUrl, children, blur = false, width = '98%'}) {
    return (
        <div
            style={{
                width: width, // Adjust width as needed
                height: '98%', // Adjust height as needed
                borderRadius: '10px', // Rounded corners
                backgroundSize: 'cover',
                backgroundImage: `url(${imageUrl})`,
                position: 'relative',
                overflow: 'hidden',
                filter: blur ? 'blur(8px)' : 'blur(0px)',
            }}
        >
            {children}
        </div>
    );
}

export default ImageContainer;
