import React from 'react'
import Home from '../Home'
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer/Footer';


const Landing = () => {
  return (
    <div>
       <Home />
       <Footer />
    </div>
  )
}

export default Landing