export const icons = [
    {title: 'Date', image: 'https://i.ibb.co/mD96WJj/Group-2792.png'},
    {title: 'Marry', image: 'https://i.ibb.co/4pWN49Y/Group-2793.png'},
    {title: 'Hookup', image: 'https://i.ibb.co/3CFmz4m/Group-2794.png'},
    {title: 'Prank', image: 'https://i.ibb.co/qsy5RNv/Group-2795.png'},
]


export const iconsData = {
    date: 'https://i.ibb.co/mD96WJj/Group-2792.png',
    marry: 'https://i.ibb.co/4pWN49Y/Group-2793.png',
    hookup: 'https://i.ibb.co/3CFmz4m/Group-2794.png',
    prank: 'https://i.ibb.co/qsy5RNv/Group-2795.png',
    cross: 'https://i.ibb.co/0MTcszV/DWD.png',
    tick: 'https://i.ibb.co/MCK95G4/Group-2798-1.png',
    prankEmoji: 'https://i.ibb.co/Yk9hzN6/JKLH.png',
    exitLoader: 'https://i.ibb.co/6b1wN4B/Rectangle-1591-2.png',
    arrowGolden: 'https://i.ibb.co/By4wTgV/Group-2743.png',
    cmfWhite: 'https://i.ibb.co/yyjy9t4/checkout-my-Friend.png',
    pointyFinger: 'https://i.ibb.co/NKFwFmK/finger.png',
    noEyes: 'https://i.ibb.co/2t1KBWP/no-eyes.png',
    menuIcon: 'https://i.ibb.co/37RJLbd/Whats-App-Image-2024-05-28-at-12-12-1.png',
    blurredName: 'https://i.ibb.co/nzHX9xS/Name.png',
    blurredTime: 'https://i.ibb.co/PhXpm4M/Time.png',
    questionMark: 'https://i.ibb.co/J5QXhvj/Group-2705.png', 
    meme: 'https://i.ibb.co/GFT7G1P/meme.png',
    yellowBtn: 'https://i.ibb.co/9sYP3L0/Rectangle-3-R3-QRT3-1.png',
    consentMessage: 'https://i.ibb.co/N2NCNXL/trrg.png',
    joinButton: 'https://i.ibb.co/bdg1n5h/b3.png',
    postDeleted: 'https://i.ibb.co/6bxJ5sz/The-post-is-deleted.png',
    thatsCool:'https://i.ibb.co/BtZ40PG/That-s-Cool.png',
    findOut: 'https://i.ibb.co/9sw2qBY/b1.png',
    sendPrank: 'https://i.ibb.co/9q2HrcR/b2-1.png'
}